import { LayersList } from '@deck.gl/core/typed';
import { Box, styled } from '@mui/material';
import { LocationPin } from '@styled-icons/entypo';
import { featureCollection, point } from '@turf/helpers';
import { BBox2d } from '@turf/helpers/dist/js/lib/geojson';
import { Polygon, Position, bbox } from '@turf/turf';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import Icon, { IconColor, IconNameType } from 'designSystem/Primitives/Icon/Icon';
import { Feature, FeatureCollection, Geometry } from 'geojson';
import mapboxgl, { Anchor, Map } from 'mapbox-gl';
import React, {
  ComponentProps,
  FC,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import MapGL, {
  FullscreenControl,
  MapEvent,
  Marker,
  MarkerDragEvent,
  NavigationControl,
  Popup,
  ScaleControl,
} from 'react-map-gl';
import { MapCallbacks } from 'react-map-gl/dist/esm/types/events-mapbox';
import { v4 as uuid } from 'uuid';
import DeckGLOverlay from './utils/DeckOverlay';
import DrawControl from './utils/DrawControl';
import { MAP_STYLES, MAX_AUTO_ZOOM_LEVEL, MAX_FOCUS_ANIMATION_TIME } from './utils/map.config';

export type MapStyle = 'default' | 'satellite' | 'outdoors';

export interface IMapPopup {
  id?: string;
  coordinate: Position;
  content: ReactNode;
}

export interface IMapMarkerOptions {
  customIcon?: IconNameType;
  /**
   * Icon color from type or hex / string value
   * Please prefer to use the IconColor type and ONLY use string if you need a custom color (e.g. hex value from a palette)
   */
  color?: IconColor | string;
  anchor?: Anchor;
  draggable?: boolean;
  /** @default true */
  focusOnClick?: boolean;
}

export interface IMapMarker {
  id?: string;
  coordinate: Position;
  /** @default anchor is center */
  options?: IMapMarkerOptions;
}

export interface MapMarkerClickEvent {
  id?: string;
  coordinate: Position;
}

export interface ICustomMapRef {
  centerFeatures: () => void;
  customFitBounds: (bounds: [number, number, number, number]) => void;
}

/**
 * Only expose the properties that are really needed and used for the component
 */
export interface ICustomMapProps
  extends Pick<
      ComponentProps<typeof MapGL>,
      'style' | 'initialViewState' | 'longitude' | 'latitude' | 'zoom'
    >,
    MapCallbacks {
  infoPopup?: IMapPopup;
  /** The features that should be drawn on the map initially */
  defaultDrawFeatures?: Feature | FeatureCollection | Geometry;
  /** You can pass a set of deck gl layer that will be rendered on top of the map */
  layers?: LayersList | undefined;
  mapStyle?: MapStyle;
  /** An array of lat, lng position where a marker will be placed on the map
   * Warning this are x, y coordinates [lng, lat]
   * The marker is currently always draggable
   */
  markers?: (IMapMarker | Position)[];

  /**
   * Set the default options for all the markers
   * Will be overridden if the marker itself has options set
   */
  markerOptions?: IMapMarkerOptions;

  /** @default false */
  config?: {
    /** @default false */
    disableControls?: boolean;
    /** @default false */
    enableCenterButton?: boolean;
    /** @default false */
    enablePolygonDrawing?: boolean;
    /** @default false */
    enableMapStyleToggle?: boolean;
    /** @default false */
    disableFullscreenControl?: boolean;
  };

  /** Reference to the component itself to call function inside of it */
  customRef?: Ref<ICustomMapRef>;

  onDrawCreate?: (event: Feature<Polygon>[]) => void;
  onDrawUpdate?: (event: Feature<Polygon>[]) => void;
  onDrawDelete?: (event: Feature<Polygon>[]) => void;
  onMarkerDragEnd?: (event: MarkerDragEvent) => void;
  onMarkerClick?: (event: MapMarkerClickEvent) => void;
  onMarkerPopupClose?: () => void;
  onMapLoad?: (map: MapEvent['target']) => void;
}

const StyledLocationPin = styled(LocationPin)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const StyledNavigationControl = styled(NavigationControl)(({ theme }) => ({
  right: 10,
  top: 10,
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(5),
  right: theme.spacing(1),
  zIndex: 9,
}));

const HoverContainer = styled('div')<{ hoverEnabled: boolean }>(({ theme, hoverEnabled }) => ({
  ':hover': {
    cursor: hoverEnabled ? 'pointer' : 'grab',
  },
}));

export const CustomMap: FC<ICustomMapProps> = ({
  customRef,
  defaultDrawFeatures,
  markers,
  markerOptions,
  infoPopup,
  mapStyle = 'default',
  layers,
  initialViewState,
  config,
  style,
  onMapLoad,
  onDrawCreate,
  onDrawUpdate,
  onDrawDelete,
  onMarkerDragEnd,
  onMarkerClick,
  onMarkerPopupClose,
  ...props
}) => {
  const [currentMapStyle, setCurrentMapStyle] = useState<MapStyle>(mapStyle || 'satellite');
  /**
   * For some reason the map ref is always null initially, so we need to store it in a state
   */
  const [mapRef, setMapRef] = useState<Map | null>(null);
  const [drawRef, setDrawRef] = useState<MapboxDraw | null>(null);
  const [isCursorHoveringLayer, setIsCursorHoveringLayer] = useState<boolean>(false);

  useImperativeHandle(customRef, () => ({
    centerFeatures,
    customFitBounds,
  }));

  useEffect(() => {
    if (defaultDrawFeatures && drawRef) {
      drawRef?.add(defaultDrawFeatures);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawRef]);

  /**
   * Zoom into bounds of the features initially when the map is loaded and features are present
   */
  useEffect(() => {
    // Only update the map if it is shown
    if ((layers || defaultDrawFeatures) && mapRef) {
      centerFeatures();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef]);

  /**
   * Workaround to fix the reference not being updated when the ref is passed properly
   * This is a known issue with react-map-gl in some situation but it is still unclear why it work for some of our components
   * https://stackoverflow.com/questions/71224100/react-ref-current-is-still-null-in-componentdidupdate
   * Get rid of this when the issue is fixed and use the ref directly
   */
  const updateMapRef = useCallback(
    (event: MapEvent) => {
      setMapRef(event.target);
      onMapLoad?.(event.target);
    },
    [onMapLoad]
  );

  /**
   * Handle the cursor when hovering a layer
   * Warning: This is a extremely performance heavy function
   */
  const handleCursorHoveringLayer = useCallback(
    (event: { isHovering: boolean }) => {
      setIsCursorHoveringLayer(event.isHovering);
      return event.isHovering ? 'grab' : 'pointer';
    },
    [setIsCursorHoveringLayer]
  );

  const toggleMapStyle = () => {
    if (currentMapStyle === 'default') {
      setCurrentMapStyle('outdoors');
    } else if (currentMapStyle === 'outdoors') {
      setCurrentMapStyle('satellite');
    } else {
      setCurrentMapStyle('default');
    }
  };

  const customFitBounds = useCallback(
    (bounds: [number, number, number, number]) => {
      if (!mapRef) {
        return;
      }
      mapRef.fitBounds(bounds, {
        padding: 40,
        maxDuration: MAX_FOCUS_ANIMATION_TIME,
        animate: true,
        maxZoom: MAX_AUTO_ZOOM_LEVEL,
      });
    },
    [mapRef]
  );

  /**
   * Note: For now this functions assumes for layer features, that all features are stored in the first layer
   */
  const centerFeatures = useCallback(() => {
    if (!mapRef) {
      return;
    }

    const allFeatures = [];

    if (config?.enablePolygonDrawing && drawRef?.getAll().features.length) {
      allFeatures.push(...drawRef.getAll().features);
    } else if (
      layers?.length &&
      layers[0] &&
      'state' in layers[0] &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (layers[0].props.data as any)?.features.length
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      allFeatures.push(...(layers[0].props.data as any).features);
    }

    if (markers?.length) {
      const markerFeatures = markers.map(marker => {
        const coordinate = Array.isArray(marker) ? marker : marker.coordinate;
        return point(coordinate);
      });
      allFeatures.push(...markerFeatures);
    }

    if (allFeatures.length) {
      const combinedFeatureCollection = featureCollection(allFeatures);
      customFitBounds(bbox(combinedFeatureCollection) as BBox2d);
    }
  }, [mapRef, drawRef, layers, markers, config?.enablePolygonDrawing, customFitBounds]);

  return (
    <MapGL
      mapLib={mapboxgl}
      mapboxAccessToken={process.env.REACT_APP_MAP_BOX_TOKEN}
      mapStyle={MAP_STYLES[currentMapStyle]}
      // Initial view state is set to the center of the world
      initialViewState={{ zoom: 2, latitude: 20, longitude: 25, ...initialViewState }}
      {...props}
      style={{ borderRadius: 4, ...style }}
      cursor={isCursorHoveringLayer ? 'pointer' : undefined}
      onLoad={updateMapRef}
    >
      {infoPopup && (
        <Popup
          longitude={infoPopup.coordinate[0]}
          latitude={infoPopup.coordinate[1]}
          closeOnClick
          closeButton={false}
          maxWidth="420px"
          // Raise popup to the top of the map and layers
          style={{ zIndex: 1000 }}
          onClose={onMarkerPopupClose}
        >
          {infoPopup.content}
        </Popup>
      )}

      {/* Layer overlay */}
      {layers && <DeckGLOverlay layers={layers} getCursor={handleCursorHoveringLayer} />}

      {/* Markers */}
      {markers &&
        markers.map(marker => {
          let _marker: IMapMarker;
          if (Array.isArray(marker)) {
            _marker = { options: markerOptions, coordinate: marker };
          } else {
            _marker = { options: markerOptions, ...marker };
          }

          const handleClickMarker = (e: { originalEvent: { stopPropagation: () => void } }) => {
            e.originalEvent.stopPropagation();
            onMarkerClick?.({ id: _marker.id, coordinate: _marker.coordinate });
            if (_marker.options?.focusOnClick !== false) {
              mapRef?.flyTo({
                center: { lng: _marker.coordinate[0], lat: _marker.coordinate[1] },
                padding: 40,
                animate: true,
                zoom: MAX_AUTO_ZOOM_LEVEL,
                maxDuration: MAX_FOCUS_ANIMATION_TIME,
              });
            }
          };

          return (
            <Marker
              key={_marker.id || uuid()}
              longitude={_marker.coordinate[0]}
              latitude={_marker.coordinate[1]}
              draggable={_marker.options?.draggable}
              anchor={_marker.options ? _marker.options.anchor || 'center' : 'bottom'}
              onDragEnd={onMarkerDragEnd}
              onClick={handleClickMarker}
            >
              <HoverContainer hoverEnabled={!!handleClickMarker}>
                {_marker.options?.customIcon ? (
                  <Icon
                    name={_marker.options.customIcon}
                    color={_marker.options.color}
                    size="x-large"
                  />
                ) : (
                  <StyledLocationPin size={50} />
                )}
              </HoverContainer>
            </Marker>
          );
        })}

      {/* Needs to be enabled to draw polygons on the map regardless of the drawing feature */}
      {(config?.enablePolygonDrawing || defaultDrawFeatures) && (
        <DrawControl
          position="top-left"
          displayControlsDefault={false}
          controls={{
            polygon: config?.enablePolygonDrawing,
            trash: config?.enablePolygonDrawing,
          }}
          onDrawControlInit={setDrawRef}
          onCreate={onDrawCreate}
          onUpdate={onDrawUpdate}
          onDelete={onDrawDelete}
        />
      )}

      {/* Map controls */}
      {!config?.disableControls && <StyledNavigationControl showCompass={false} />}
      {!config?.disableFullscreenControl && <FullscreenControl />}

      <ButtonContainer gap={1} display="flex" alignItems="center">
        {config?.enableCenterButton && (
          <ThemeButton
            startIcon={<Icon size="small" name="focus" />}
            color="WHITE"
            size="small"
            onClick={centerFeatures}
          >
            Center polygons
          </ThemeButton>
        )}
        {config?.enableMapStyleToggle && (
          <ThemeButton
            startIcon={<Icon size="small" name="payments" />}
            color="WHITE"
            size="small"
            onClick={toggleMapStyle}
          >
            Map style
          </ThemeButton>
        )}
      </ButtonContainer>
      <ScaleControl />
    </MapGL>
  );
};

export default CustomMap;
