import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import PartnerAutocompleteWrapper from 'components/ChainMapping/Forms/PartnerAutocomplete';
import DatasetValidationForm from 'components/ComplianceGuide/components/Forms/DatasetValidationForm';
import RawMaterialSelection from 'components/ComponentsLibrary/Forms/RawMaterialSelection';
import useDatasetMutation from 'components/ComponentsLibrary/hooks/useDatasetMutation';
import { IGridApiHandle } from 'components/DataImport/DataImportTable/ExcelTable';
import DatasetVerification from 'components/DataImport/Views/DatasetVerification';
import WarningBanner from 'components/DataImport/WarningBanner';
import { Loader, TextInputField } from 'components/Forms';
import CountrySelectField from 'components/Forms/CountrySelectField';
import { ErrorState, FlexBox, PageContainer, PageTitle } from 'components/Structure';
import { useBlockNavigation, useDialog, usePageTitle, useUploadState } from 'components/hooks';
import { datasetSchema, IDatasetFormValue } from 'constants/schemas/geoUpload.schema';
import { AlertDialog, ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Form, Formik, FormikProps } from 'formik';
import useDatasetImportOverview from 'hooks/useDatasetImportOverview';
import isEqual from 'lodash/isEqual';
import React, { FC, useEffect, useRef } from 'react';
import useMeasure from 'react-use/lib/useMeasure';
import { DatasetStatus } from 'types/dataset.types';

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  minHeight: 50,
  padding: theme.spacing(0.5),
}));

const SelectContainer = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  background: theme.custom.themeColors.white,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  '& > div': {
    flex: 3,
  },
}));

const HintContainer = styled('div')(({ theme }) => ({
  background: theme.custom.themeColors.primary[5],
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  height: 'fit-content',
}));

const DatasetImportOverview: FC = () => {
  usePageTitle('Origin data import');
  const { dataset, error, loading, isPartnerLibrary } = useDatasetImportOverview();
  const { deleteDataset, updateDataset, startDatasetProcessing } = useDatasetMutation();
  const { handleCleanCreatedRecords, cleanFiles } = useUploadState();
  const { openDialog } = useDialog();
  const gridApiRef = useRef<IGridApiHandle>(null);
  const formRef = useRef<FormikProps<IDatasetFormValue>>(null);
  const [containerRef, { height: containerHeight }] = useMeasure<HTMLDivElement>();
  const { navigationBlocked, reset, unblock } = useBlockNavigation({
    shouldBlock:
      !error &&
      dataset?.status === DatasetStatus.VALIDATION_FAILED &&
      !!dataset?.tableRepresentation?.length,
  });

  /**
   * Initial cleanup of files that were uploaded but not attached to a dataset
   */
  useEffect(() => {
    cleanFiles();
    handleCleanCreatedRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues: IDatasetFormValue = {
    ...datasetSchema.default(),
    ...(dataset
      ? {
          title: dataset.title,
          rawMaterialId: dataset.rawMaterial.id,
          countryCode: dataset.originCountry,
          ownedBy: dataset.ownedBy,
        }
      : {}),
  };

  if (loading) {
    return <Loader />;
  }
  if (
    error ||
    !dataset ||
    (dataset.status === DatasetStatus.VALIDATION_FAILED && !dataset.tableRepresentation?.length)
  ) {
    if (
      dataset &&
      dataset.status === DatasetStatus.VALIDATION_FAILED &&
      !dataset.tableRepresentation?.length
    ) {
      console.error('The dataset has no table representation');
    }
    return <ErrorState />;
  }

  // TODO: Move the row error count calculation to the backend
  const issueCount =
    dataset.tableRepresentation?.[0]?.rows?.reduce(
      (prev, row) => prev + (Object.values(row).some(value => value?.error) ? 1 : 0),
      0
    ) || 0;

  const handleDiscardDataset = () => {
    deleteDataset(dataset);
    reset();
    unblock();
  };

  const handleSubmit = async (values: IDatasetFormValue) => {
    if (dataset.status === DatasetStatus.VALIDATION_FAILED) {
      if (!gridApiRef.current) {
        // eslint-disable-next-line no-console
        console.error('Can not export the table data, because the gridApiRef is not set');
        return;
      }
      formRef.current?.setSubmitting(false);
      // Extract data as csv from table representation
      const csvData = gridApiRef.current.getDataAsCsv();
      openDialog({
        type: 'CONFIRM_DATASET_IMPORT',
        props: {
          csvData,
          dataset,
          newDatasetValues: values,
          onProcessingStart: () => formRef.current?.setSubmitting(true),
          onProcessingComplete: () => formRef.current?.setSubmitting(false),
        },
      });
    } else {
      try {
        formRef.current?.setSubmitting(true);
        // Update dataset if values changed
        if (!isEqual(initialValues, values)) {
          await updateDataset(dataset.id, {
            originCountry: values.countryCode,
            title: values.title,
            rawMaterialId: values.rawMaterialId,
          });
        }
        // Rerun verification by running the processing again
        // Start the processing of the dataset again to validate the new data
        await startDatasetProcessing([{ datasetId: dataset.id }]);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error while saving the dataset', error);
      } finally {
        formRef.current?.setSubmitting(false);
      }
    }
  };

  return (
    <PageContainer ref={containerRef}>
      <Box mb={2}>
        <PageTitle
          title="Origin data import"
          goBackLabel="All data sets"
          autoBackNavigation
          TitleAdornment={
            <FlexBox ml={1} mt={0.5}>
              <Icon name="farm" color="gray-60" size="large" />
              <Box mr={0.5} />
              <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_60">
                Farm data
              </ThemeTypography>
            </FlexBox>
          }
        />
      </Box>

      <Formik<IDatasetFormValue>
        innerRef={formRef}
        validationSchema={datasetSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <Box display="flex" flexDirection="column" gap={2}>
              <SelectContainer>
                <TextInputField
                  name="title"
                  isRequired
                  helperText="Add a name to your geo-dataset that helps you and your colleagues remember to what origin this dataset refers to. Using specific region names could be helpful."
                  label="Title"
                  size="small"
                  placeholder='E.g. "Origin, geo-data Ghana"'
                />
                <RawMaterialSelection
                  isRequired
                  multiple={false}
                  name="rawMaterialId"
                  size="small"
                  helperText="Select the commodity that is sourced from this origin. You can only choose in this list from the EUDR-affected raw materials."
                />
                <CountrySelectField
                  isRequired
                  name="countryCode"
                  label="Country of origin"
                  helperText="Select the country where this origin geo-data is located. You can only have one country per origin geo-dataset."
                />
                <PartnerAutocompleteWrapper
                  isRequired
                  name="ownedBy"
                  label="Owner"
                  disabled={isPartnerLibrary || dataset.status !== DatasetStatus.VALIDATION_FAILED}
                  helperText="Select an owner of this origin geo-dataset. This would optimally be a supplier that is involved in the supply chain connected to this origin. You can also select your own company."
                />
                <FlexBox justifyContent="flex-end">
                  <ThemeButton type="submit" disabled={!isValid} loading={isSubmitting}>
                    Save & verify
                  </ThemeButton>
                </FlexBox>
              </SelectContainer>

              {dataset.status !== DatasetStatus.VERIFICATION_FAILED && (
                <HintContainer>
                  <ThemeTypography variant="BODY_MEDIUM" color="BLUE_80">
                    If you plan to use this geo-data for EUDR compliance, it is recommended to
                    include the size of each farm plot. For single location plots that are missing a
                    size attribute, you can add the size here. Otherwise, when this geo-data is used
                    for an EUDR deforestation analysis, the plot size of each plot with a single
                    coordinate will during the analysis default to 4 ha, according to regulation
                    requirements.
                  </ThemeTypography>
                </HintContainer>
              )}

              {dataset.status === DatasetStatus.VALIDATION_FAILED &&
                dataset.tableRepresentation?.length && (
                  <>
                    {dataset.status === DatasetStatus.VALIDATION_FAILED &&
                      dataset.tableRepresentation?.length && (
                        <WarningBanner
                          severity="error"
                          message={`Dataset could not be saved. ${issueCount} rows contain errors. Please
                          correct all errors per row in the table below.`}
                          issueCount={issueCount}
                        />
                      )}

                    <Container>
                      <DatasetValidationForm
                        gridRef={gridApiRef}
                        height={containerHeight - 400}
                        mode="edit"
                        tableRepresentation={dataset.tableRepresentation[0]}
                      />
                    </Container>
                  </>
                )}

              {dataset.status !== DatasetStatus.VALIDATION_FAILED && (
                <Container>
                  <DatasetVerification dataset={dataset} />
                </Container>
              )}
            </Box>
          </Form>
        )}
      </Formik>

      <AlertDialog
        open={navigationBlocked}
        title="Unsaved dataset"
        text={
          <Box>
            <ThemeTypography variant="BODY_MEDIUM">
              Your dataset has formatting errors and can not be saved until these errors are
              corrected. If you exit without saving, your data import will be lost. Do you want to
              continue to edit your import?
            </ThemeTypography>
          </Box>
        }
        cancelText="Discard & exit"
        submitText="Continue editing"
        onClose={reset}
        onSubmit={reset}
        onCancel={handleDiscardDataset}
        displayCloseButton
      />
    </PageContainer>
  );
};

export default DatasetImportOverview;
