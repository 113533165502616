import { gql } from '@apollo/client';
import {
  COMPLIANCE,
  EUDR_BASE_STATEMENT,
  EUDR_STATEMENT,
  GEOC_BATCH,
  GEOC_PARCEL,
  QUESTIONNAIRE_RESPONSE_OPTION_FRAGMENT,
} from 'graphql/fragments/compliance.fragments';

export const GET_COMPLIANCES = gql`
  query GetCompliances {
    compliances {
      ...compliance
    }
  }
  ${COMPLIANCE}
`;

export const GET_STATEMENTS = gql`
  query GetStatements($complianceId: UUID!, $first: Int, $after: String) {
    statements(complianceId: $complianceId, first: $first, after: $after) {
      edges {
        node {
          ...eudrBaseStatementValues
        }
      }
      count
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${EUDR_BASE_STATEMENT}
`;

export const GET_STATEMENT = gql`
  query GetStatement($id: UUID!) {
    statement(id: $id) {
      ...eudrStatementValues
    }
  }
  ${EUDR_STATEMENT}
`;

export const GET_GEOC_BATCHES = gql`
  query GetGeocBatches($datasetId: UUID, $eudrStatementId: UUID!) {
    geocBatches(datasetId: $datasetId, eudrStatementId: $eudrStatementId) {
      ...geocBatchValues
    }
  }
  ${GEOC_BATCH}
`;

export const GET_GEOC_PARCELS = gql`
  query GetParcels($batchId: UUID, $eudrStatementId: UUID, $datasetId: UUID) {
    parcels(batchId: $batchId, eudrStatementId: $eudrStatementId, datasetId: $datasetId) {
      edges {
        node {
          ...geocParcelValues
        }
      }
      highRisks
      mediumRisks
      lowRisks
      count
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${GEOC_PARCEL}
`;

export const GET_QUESTIONNAIRE_RESPONSES = gql`
  query QuestionnaireResponses(
    $statementId: UUID!
    $filters: QuestionnaireResponsesFilters!
    $orderBy: QuestionnaireResponsesOrderBy
  ) {
    questionnaireResponses(statementId: $statementId, filters: $filters, orderBy: $orderBy) {
      edges {
        node {
          text
          options {
            ...questionnaireResponseOptionValues
          }
        }
      }
      summary {
        averageResponseTime
        riskMode
      }
      count
    }
  }
  ${QUESTIONNAIRE_RESPONSE_OPTION_FRAGMENT}
`;
