import { Box } from '@mui/material';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import QnAGuideTemplate from 'components/ComplianceGuide/components/QnAGuideTemplate';
import { InlineHelperText } from 'components/Product/InlineHelperText/InlineHelperText';
import React, { FC, useEffect, useState } from 'react';
import { ThemeTypography } from 'designSystem';
import { DatasetStatus } from 'types/dataset.types';

const GeoDataPractice: FC = () => {
  const [selectedAnswer, setSelectedAnswer] = useState<string | undefined>();

  const { statement, setSelectedSubSection, formData } = useEudrComplianceGuide();

  const handleClickNextStep = () => {
    if (selectedAnswer === 'upload-geo-data') {
      setSelectedSubSection('upload');
    }

    if (selectedAnswer === 'reuse-geo-data') {
      setSelectedSubSection('select-geo-data');
    }
  };

  // Route to the correct sub section if parts were already done
  useEffect(() => {
    if (statement) {
      if (statement.datasets.some(dataset => dataset.status === DatasetStatus.MAPPING_FAILED)) {
        setSelectedSubSection('map-columns');
      }
      if (statement.datasets.some(dataset => dataset.status === DatasetStatus.VALIDATION_FAILED)) {
        if (formData?.datasetMappings) {
          setSelectedSubSection('map-columns');
        } else {
          // TODO: We need a way of receiving the mapping of failed datasets again. For now the user has to call processing again
          setSelectedSubSection('upload');
        }
      }
      if (
        statement.datasets.length &&
        statement.datasets.every(
          dataset =>
            dataset.status === DatasetStatus.NORMALISED ||
            dataset.status === DatasetStatus.COMPLETED
        )
      ) {
        setSelectedSubSection('confirmation');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statement]);

  return (
    <EudrComplianceGuideSubSection
      allowNextStepNavigation={!!selectedAnswer}
      onNextStepClick={handleClickNextStep}
    >
      <Box mb={2}>
        <ThemeTypography variant="BODY_MEDIUM">
          If you already have the required geo-data for your origins, you can import it here.
        </ThemeTypography>
      </Box>

      <QnAGuideTemplate
        multipleAnswers={false}
        selectedAnswer={selectedAnswer}
        question="Select the option that best describes your company's sourcing practices"
        answers={[
          {
            id: 'upload-geo-data',
            label: 'I have the required geo-data available and need to upload.',
          },
          {
            id: 'reuse-geo-data',
            label: 'I want to re-use previously uploaded geo-datasets.',
          },
          {
            id: 'request-geo-data',
            label: 'I want to request geo-data from my suppliers.',
            disabled: true,
          },
          {
            id: 'draw-geo-data',
            label:
              'I have single locations for farm plots and want to draw the plot boundaries to create polygons.',
            disabled: true,
          },
        ]}
        onAnswerChange={setSelectedAnswer}
      />
      <Box mt={3} />
      <InlineHelperText
        variant="INFO"
        helperText="Seedtrace are working on adding more support for you and your suppliers to collect geo-data. The compliance guide will soon support you in creating adapted requests to your suppliers, giving them clear instructions of how to upload and normalise data or how to draw their polygon geo-data on a map."
      />
    </EudrComplianceGuideSubSection>
  );
};

export default GeoDataPractice;
