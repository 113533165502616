import { Box, DialogActions, DialogContent, styled } from '@mui/material';
import { useDialog } from 'components/hooks';
import {
  datasetImportSchema,
  IDatasetFormValue,
  IDatasetImportFormValues,
} from 'constants/schemas/geoUpload.schema';
import { DialogDefault, InfoTooltip, ThemeButton, ThemeTypography } from 'designSystem';
import { Field, Form, Formik } from 'formik';
import { Switch } from 'formik-mui';
import React, { FC } from 'react';
import { IDataset } from 'types/dataset.types';
import { IDefaultDialogProps } from 'types/dialog.types';

interface IConfirmDatasetImportDialogProps extends IDefaultDialogProps {
  csvData: string;
  dataset: IDataset;
  newDatasetValues: IDatasetFormValue;
  onProcessingStart: () => void;
  onProcessingComplete: () => void;
}

const FormItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.custom.themeColors.grayScale[5],
  padding: theme.spacing(0, 2),
}));

const StyledTooltip = styled(InfoTooltip)(() => ({
  margin: 0,
}));

const ConfirmDatasetImportDialog: FC<IConfirmDatasetImportDialogProps> = ({
  open,
  csvData,
  dataset,
  newDatasetValues,
  onProcessingComplete,
  onProcessingStart,
  onClose,
}) => {
  const { openDialog } = useDialog();

  const handleSubmit = async (values: IDatasetImportFormValues) => {
    openDialog({
      type: 'CONFIRM_FARM_CLUSTERING',
      props: {
        csvData,
        dataset,
        newDatasetValues,
        overwriteExistingFarmData: values.overwriteExisting,
        onProcessingComplete,
        onProcessingStart,
      },
    });
    onClose?.();
  };

  return (
    <DialogDefault open={open} title="Confirm Import settings" onClose={onClose}>
      <Formik
        initialValues={datasetImportSchema.default()}
        validationSchema={datasetImportSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogContent>
              <Box display="flex" flexDirection="column">
                <ThemeTypography variant="BODY_MEDIUM_BOLD">
                  Some farms may already exist in the platform (sharing the same internal ID). Would
                  you like to update and overwrite the existing farm data with the imported data?
                </ThemeTypography>
                <FormItem>
                  <ThemeTypography variant="BODY_MEDIUM">
                    Import and update farm data for existing farm ids:
                  </ThemeTypography>
                  <Field name="overwriteExisting" fullWidth component={Switch} type="checkbox" />
                  <StyledTooltip
                    variant="INFO"
                    text="Enable this to update the existing Farm data with the new imported Farm data for the Farm ids that already exist in the platform. The old Farm data will be kept as history in the platform and any changes in the imported data will become the new current status for this Farm."
                  />
                </FormItem>
              </Box>
            </DialogContent>

            <DialogActions>
              <Box display="flex" justifyContent="space-between" width="100%">
                <ThemeButton color="BLUE_ICE" onClick={onClose}>
                  Cancel
                </ThemeButton>
                <ThemeButton type="submit" loading={isSubmitting}>
                  Confirm
                </ThemeButton>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </DialogDefault>
  );
};

export default ConfirmDatasetImportDialog;
