import { gql } from '@apollo/client';
import { BASE_PARTNER_COMPANY_FRAGMENT } from './partner.fragments';

export const BASE_DATASET = gql`
  fragment baseDatasetValues on Dataset {
    id
    createdTimestamp
    modifiedTimestamp
    title
    originCountry
    status
    rawMaterial {
      title
    }
    ownedBy {
      name
    }
    issues {
      entityId
      entityType
      errorMessage
    }
  }
`;

export const DATASET_TABLE_REPRESENTATION_CELL_FRAGMENT = gql`
  fragment datasetTableRepresentationCellValues on DatasetCell {
    value
    error {
      entityId
      entityType
      errorMessage
    }
  }
`;

export const DATASET_TABLE_REPRESENTATION_FRAGMENT = gql`
  fragment datasetTableRepresentationValues on DatasetTableRepresentation {
    columnsNames
    rows {
      farmName {
        ...datasetTableRepresentationCellValues
      }
      farmId {
        ...datasetTableRepresentationCellValues
      }
      coordinates {
        ...datasetTableRepresentationCellValues
      }
      farmSize {
        ...datasetTableRepresentationCellValues
      }
    }
  }
  ${DATASET_TABLE_REPRESENTATION_CELL_FRAGMENT}
`;

export const GEOC_BATCH = gql`
  fragment geocBatchValues on GeocBatch {
    id
    createdTimestamp
    modifiedTimestamp
    externalBatchId
    geocBatchId
    datasetId
    weight
    riskStatus
    harvestDate
  }
`;

export const DATASET_FRAGMENT = gql`
  fragment datasetValues on Dataset {
    ...baseDatasetValues
    geocBatches {
      ...geocBatchValues
    }
    ownedBy {
      ...basePartnerCompanyValues
    }
    rawMaterial {
      id
      title
    }
    documents {
      id
      title
    }
  }
  ${GEOC_BATCH}
  ${BASE_DATASET}
  ${BASE_PARTNER_COMPANY_FRAGMENT}
`;

export const DATASET_MAPPING_FRAGMENT = gql`
  fragment datasetMappingValues on DatasetMapping {
    datasetId
    columnsData {
      columnName
      columnDataExample
    }
  }
`;
