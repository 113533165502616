import { Box, Grid, Link } from '@mui/material';
import { PartnerPlaceholderImage } from 'assets/img';
import PolygonDataCard from 'components/CultivatedFarmArea/PolygonDataCard';
import { Loader } from 'components/Forms';
import { useDialog, usePageTitle } from 'components/hooks';
import { ErrorState, FlexBox, PageContainer, PageSubTitle, PageTitle } from 'components/Structure';
import OverviewHeader from 'components/Structure/OverviewHeader/OverviewHeader';
import { ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import useSite from 'hooks/useSite';
import React, { FC, useMemo } from 'react';
import { IBaseFarmSite, SiteType } from 'types/site.types';
import { SiteLabelKeys } from 'utils/site.utils';
import Image from 'designSystem/DataDisplay/Image/Image';
import CultivationFarmArea from 'components/CultivatedFarmArea/CultivationFarmArea';
import SitePlaceholderImage from 'assets/img/site-placeholder.png';
import SitesPaginatedTable from 'components/Sites/SitesTable/SitesPaginatedTable';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material';

const LinkContainer = styled(Box)(({ theme }) => ({
  a: {
    textDecoration: 'none',
  },
}));

const SiteOverview: FC = () => {
  usePageTitle('Site overview');
  const { site, loading, error, handleEditCultivatedArea, handleEditSite, handleDeleteSite } =
    useSite();
  const { openDialog } = useDialog();

  const custerHasCultivatedAreas = useMemo(() => {
    if (
      !site ||
      site.siteType !== SiteType.CLUSTER ||
      !site.sites?.edges.length ||
      site.sites?.edges[0].node.siteType !== SiteType.FARM
    )
      return false;

    return site.sites.edges.some(
      ({ node }) => node.siteType === SiteType.FARM && node.cultivatedAreas?.length
    );
  }, [site]);

  const isCluster = site?.siteType === SiteType.CLUSTER;

  const handleCreateSiteForCluster = () => {
    if (!isCluster) return;

    openDialog({
      type: 'ADD_EDIT_SITE',
      props: {
        siteClusterId: site.id,
        defaultSiteType: site?.sites?.edges[0]?.node.siteType,
        defaultRawMaterialId: (site?.sites?.edges[0]?.node as IBaseFarmSite)?.rawMaterial?.id,
        defaultCountry: site?.locationName,
      },
    });
  };

  if (loading) {
    return <Loader />;
  }
  if (error || !site) {
    return <ErrorState action={undefined} />;
  }

  return (
    <PageContainer>
      <PageTitle
        autoBackNavigation
        title={isCluster ? 'Cluster overview' : 'Site overview'}
        goBackLabel="All sites"
        titleExtension={site.title}
      />
      <Box mb={3} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <OverviewHeader image={site.image} imageUrl={SitePlaceholderImage}>
            <Box
              display="flex"
              alignItems="flex-start"
              flexDirection="column"
              height="100%"
              justifyContent="space-evenly"
            >
              <FlexBox>
                <Box mb={1}>
                  <ThemeTypography variant="TITLE_MEDIUM" autoOverflow maxWidth={550}>
                    {site.title}
                  </ThemeTypography>
                </Box>

                {site.ownedBy && (
                  <Box display="flex" alignItems="center" ml={2}>
                    <Image
                      image={site.ownedBy?.logo}
                      backupImageUrl={PartnerPlaceholderImage}
                      width={42}
                      height={42}
                    />
                    <Box ml={1}>
                      <ThemeTypography variant="ITEM_SUBTITLE">
                        {site.ownedBy?.name}
                      </ThemeTypography>
                    </Box>
                  </Box>
                )}

                {site.externalId && (
                  <FlexBox ml={2}>
                    <ThemeTypography variant="TITLE_EXTRA_SMALL" color="GRAY_40">
                      |
                    </ThemeTypography>
                    <Box mr={1} />
                    <ThemeTypography
                      variant="TITLE_EXTRA_SMALL"
                      autoOverflow
                      maxWidth={200}
                      color="GRAY_40"
                    >
                      {site.externalId}
                    </ThemeTypography>
                  </FlexBox>
                )}
              </FlexBox>

              <FlexBox mb={1} gap={1}>
                <FlexBox gap={0.5}>
                  <Icon name="tag-filled" color="gray-80" size="small" />
                  <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                    {SiteLabelKeys[site.siteType]}
                  </ThemeTypography>
                </FlexBox>
                {isCluster && !!site?.sites.count && (
                  <FlexBox gap={0.5}>
                    <Icon name="farm" size="small" color="gray-80" />
                    <ThemeTypography variant="LABEL_INPUT" color="GRAY_80">
                      {site.sites.count} {SiteLabelKeys[site.sitesType].toLowerCase()}
                      {site.sites.count <= 1 ? '' : 's'}
                    </ThemeTypography>
                  </FlexBox>
                )}
                <FlexBox gap={0.5}>
                  <Icon name="location" size="small" color="gray-80" />
                  <ThemeTypography variant="LABEL_INPUT" color="GRAY_80">
                    {site.locationName}
                  </ThemeTypography>
                </FlexBox>
              </FlexBox>

              <ThemeButton
                startIcon={<Icon name="edit" />}
                size="large"
                color="YELLOW"
                loading={false}
                onClick={() => handleEditSite()}
              >
                {isCluster ? 'Edit cluster' : 'Edit site'}
              </ThemeButton>
            </Box>
          </OverviewHeader>
        </Grid>

        {site.siteType === SiteType.CLUSTER && (
          <>
            {!!site.sites.count && (
              <Grid item xs={6}>
                <Box flex={1}>
                  <PageSubTitle
                    title="Cluster Sites"
                    visibleItems={site.sites.edges.length}
                    totalItems={site.sites.count}
                    mb={2}
                  >
                    <LinkContainer display="flex" alignItems="center" gap={2}>
                      <Link
                        component={RouterLink}
                        to="sites"
                        color="secondary"
                        className="small"
                        data-cy="link-see-all-sites"
                      >
                        See all
                      </Link>
                      <ThemeButton
                        startIcon={<Icon name="plus" size="small" />}
                        size="small"
                        color="WHITE"
                        onClick={handleCreateSiteForCluster}
                      >
                        Add new
                      </ThemeButton>
                    </LinkContainer>
                  </PageSubTitle>
                  <SitesPaginatedTable
                    sites={site.sites.edges.map(edge => edge.node)}
                    onSiteEdit={handleEditSite}
                    onSiteDelete={handleDeleteSite}
                    onEditCultivatedArea={handleEditCultivatedArea}
                  />
                </Box>
              </Grid>
            )}

            {isCluster && custerHasCultivatedAreas && (
              <Grid item xs={6}>
                <Box flex={1}>
                  <PageSubTitle title="Cultivation areas" mb={2} />
                  <CultivationFarmArea
                    farms={
                      site.sites.edges
                        .map(edge => edge.node)
                        .filter(({ siteType }) => siteType === SiteType.FARM) as IBaseFarmSite[]
                    }
                    onEditCultivatedAreaClick={farmId =>
                      handleEditCultivatedArea(
                        site.sites.edges.find(({ node: { id } }) => id === farmId)?.node
                      )
                    }
                  />
                </Box>
              </Grid>
            )}
          </>
        )}

        {site.siteType === SiteType.FARM && (
          <Grid item xs={6}>
            <Box flex={1} data-cy="cultivated-area" minHeight={400}>
              <PageSubTitle title="Cultivation areas" spaceBetween={false} mb={2} />
              {site.cultivatedAreas?.length ? (
                <CultivationFarmArea
                  farms={[site]}
                  onEditCultivatedAreaClick={() => handleEditCultivatedArea()}
                />
              ) : (
                <PolygonDataCard site={site} onAddPolygonClick={() => handleEditCultivatedArea()} />
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </PageContainer>
  );
};

export default SiteOverview;
