import React, { FC } from 'react';
import { StyledTableBody } from 'components/TableBase';
import { HeaderColumn } from 'types/types';
import { IClusterSite, ISite, SiteType } from 'types/site.types';
import SiteTableRow from './SiteTableRow';
import SiteClusterTableRow from './SiteClusterTableRow';

interface ISitesTableBodyProps {
  sites: ISite[];
  headerColumns: HeaderColumn[];
  onSiteClick?: (site: ISite) => void;
  onSiteEdit?: (site: ISite) => void;
  onSiteClusterEdit?: (site: IClusterSite) => void;
  onSiteClusterDelete?: (siteId: string) => void;
  onSiteDelete?: (siteId: string) => void;
  onManageClusterSites?: (siteClusterId: string) => void;
  onEditCultivatedArea?: (site: ISite) => void;
}

const SitesTableBody: FC<ISitesTableBodyProps> = ({
  sites,
  headerColumns,
  onSiteClick,
  onSiteEdit,
  onSiteClusterEdit,
  onSiteClusterDelete,
  onSiteDelete,
  onManageClusterSites,
  onEditCultivatedArea,
}) => {
  return (
    <StyledTableBody data-cy="sites-table-body">
      {sites.map(site => {
        const { id, siteType } = site;
        const labelId = `site-table-${id}`;
        // isCluster if type of site is SiteCluster
        const isCluster = siteType === SiteType.CLUSTER;

        if (isCluster)
          return (
            <SiteClusterTableRow
              key={labelId}
              siteCluster={site}
              headerColumns={headerColumns}
              onSiteClick={onSiteClick}
              onSiteEdit={onSiteEdit}
              onSiteClusterEdit={onSiteClusterEdit}
              onSiteClusterDelete={onSiteClusterDelete}
              onSiteDelete={onSiteDelete}
              onManageClusterSites={onManageClusterSites}
              onEditCultivatedArea={onEditCultivatedArea}
            />
          );

        return (
          <SiteTableRow
            key={labelId}
            site={site}
            headerColumns={headerColumns}
            onEdit={onSiteEdit}
            onRowClick={onSiteClick}
            onDelete={onSiteDelete}
            onEditCultivatedArea={onEditCultivatedArea}
          />
        );
      })}
    </StyledTableBody>
  );
};

export default SitesTableBody;
