import { Box, styled } from '@mui/material';
import CardContainer from 'designSystem/Layout/CardContainer/CardContainer';
import React, { FC } from 'react';
import { IClusterSite, SiteType, SiteTypeWithoutCluster } from 'types/site.types';
import Image from 'designSystem/DataDisplay/Image/Image';
import Icon from 'designSystem/Primitives/Icon/Icon';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import { SiteLabelKeys } from 'utils/site.utils';

interface IClusterSummaryProps {
  siteCluster: IClusterSite;
  siteCount: number;
  siteType: SiteTypeWithoutCluster;
  rawMaterialTitle?: string;
}

const ClusterContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.custom.themeColors.primary[5],
  borderRadius: 6,
  border: `1px solid ${theme.custom.themeColors.primary[40]}`,
  width: '100%',
}));

const ClusterSummary: FC<IClusterSummaryProps> = ({
  siteCluster,
  siteType,
  rawMaterialTitle,
  siteCount,
}) => {
  return (
    <ClusterContainer>
      <CardContainer hasShadow={false} padding={1.5} hasBorder>
        <Box display="flex" alignItems="center">
          {!!siteCluster.image ? (
            <Image width={44} height={44} image={siteCluster.image} />
          ) : (
            <Box display="flex" bgcolor="white" borderRadius="6px" padding="4px">
              <Icon name="site-cluster-placeholder" size="xx-large" color="blue-ice" />
            </Box>
          )}
          <Box ml={1.5}>
            <ThemeTypography variant="ITEM_TITLE">{siteCluster.title}</ThemeTypography>
            <Box display="flex" alignItems="center">
              <Icon name="tag-filled" size="small" color="gray-60" />
              <Box ml={0.5} />
              <ThemeTypography variant="LABEL_INPUT">Origin cluster</ThemeTypography>
              <Box ml={1} />
              <Icon name="farm" size="medium" color="gray-60" />
              <Box ml={0.5} />
              <ThemeTypography variant="LABEL_INPUT">
                {siteCount} {siteType ? `${SiteLabelKeys[siteType].toLowerCase()}s` : 'sites'}
              </ThemeTypography>
              <Box ml={1} />
              <Icon name="location" size="small" color="gray-60" />
              <Box ml={0.5} />
              <ThemeTypography variant="LABEL_INPUT">{siteCluster.locationName}</ThemeTypography>
            </Box>
          </Box>
        </Box>
      </CardContainer>
      <Box mt={1}>
        <ThemeTypography variant="BODY_SMALL" color="BLUE_100">
          Sites must share certain properties to be in the same origin cluster:
          {siteType ? (
            <li>
              <b>Site type:</b> {SiteLabelKeys[siteType]}
            </li>
          ) : undefined}
          <li>
            <b>Location:</b> Within the same country ({siteCluster.locationName})
          </li>
          {siteType
            ? siteType === SiteType.FARM && (
                <li>
                  <b>Focus crop/material:</b> {rawMaterialTitle}
                </li>
              )
            : undefined}
        </ThemeTypography>
      </Box>
    </ClusterContainer>
  );
};

export default ClusterSummary;
