import { useMutation, useQuery } from '@apollo/client';
import { Box, DialogActions, DialogContent, styled } from '@mui/material';
import useDatasetMutation from 'components/ComponentsLibrary/hooks/useDatasetMutation';
import { useDocumentUpload } from 'components/DocumentLibrary/hooks';
import { DropDown, Loader } from 'components/Forms';
import {
  datasetFarmClusteringSchema,
  IDatasetFormValue,
  IDatasetFarmClusteringFormValues,
} from 'constants/schemas/geoUpload.schema';
import { DialogDefault, InfoTooltip, ThemeButton, ThemeTypography } from 'designSystem';
import { Field, Form, Formik } from 'formik';
import { Switch } from 'formik-mui';
import { DELETE_DOCUMENTS } from 'graphql/mutations';
import { GET_DATASET_CLUSTERS } from 'graphql/queries/dataset.queries';
import omit from 'lodash/omit';
import React, { FC } from 'react';
import { IDataset } from 'types/dataset.types';
import { IDefaultDialogProps } from 'types/dialog.types';
import { DocumentCategories } from 'types/document.types';
import { IBaseFarmSite, IClusterSite } from 'types/site.types';
import { CardContainer } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { AvailableSizes } from 'types/enums';
import { Booleanish, booleanish } from 'types/booleanish.types';

type IClusterSiteOption = { title: string; value: string | null };

interface IConfirmDatasetImportDialogProps extends IDefaultDialogProps {
  csvData: string;
  dataset: IDataset;
  newDatasetValues: IDatasetFormValue;
  overwriteExistingFarmData: boolean;
  onProcessingStart: () => void;
  onProcessingComplete: () => void;
}

const FormItem = styled('div')<{ whiteBackground?: booleanish }>(({ theme, whiteBackground }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  borderRadius: theme.spacing(0.5),
  backgroundColor: whiteBackground === 'true' ? '#fff' : theme.custom.themeColors.grayScale[5],
  padding: theme.spacing(0, 2),
}));

const DropdownContainer = styled(Box)(({ theme }) => ({
  borderRadius: 6,
  padding: theme.spacing(2),
  backgroundColor: theme.custom.themeColors.grayScale[5],

  '& .MuiSelect-select': {
    background: '#fff !important',
    borderRadius: 4,
    border: `1px solid #EEEEEE`,
    padding: '6px 8px !important',
  },
}));

const StyledTooltip = styled(InfoTooltip)(() => ({
  margin: 0,
}));

const ConfirmDatasetImportDialog: FC<IConfirmDatasetImportDialogProps> = ({
  open,
  csvData,
  dataset,
  newDatasetValues,
  overwriteExistingFarmData,
  onProcessingComplete,
  onProcessingStart,
  onClose,
}) => {
  const [uploadFile] = useDocumentUpload();
  const [deleteDocument] = useMutation(DELETE_DOCUMENTS);
  const { updateDataset, startDatasetProcessing } = useDatasetMutation();
  const { data: datasetClusters, loading: datasetClustersLoading } = useQuery<{
    datasetClusters: IClusterSite[];
  }>(GET_DATASET_CLUSTERS, { variables: { datasetId: dataset.id } });

  const clusters = datasetClusters?.datasetClusters || [];
  const hasClusters = !!datasetClusters?.datasetClusters?.length;

  const dropdownOptions: IClusterSiteOption[] = clusters.length
    ? [
        ...clusters.map(cluster => ({ title: cluster.title, value: cluster.id })),
        { title: 'New cluster', value: null },
      ]
    : [];

  const handleSubmit = async (values: IDatasetFarmClusteringFormValues) => {
    onProcessingStart();
    try {
      // Create a file from the csv data
      const file = new File([csvData], `${newDatasetValues.title}-validated.csv`, {
        type: 'text/csv',
      });

      // Upload the file to the document library
      // @ts-ignore
      const documentCreationResponse = await uploadFile({
        file,
        category: DocumentCategories.GEOGRAPHICAL_FEATURES,
      });

      // Delete old document on the dataset does not
      deleteDocument({ variables: { ids: dataset.documents.map(({ id }) => id) } });

      if (documentCreationResponse?.record.id) {
        // Update the dataset with the new document and eventually with the new values
        const datasetUpdateResponse = await updateDataset(dataset.id, {
          documentIds: [documentCreationResponse.record.id],
          originCountry: newDatasetValues.countryCode,
          ...omit(newDatasetValues, ['ownedBy', 'countryCode']),
        });

        if (datasetUpdateResponse.data?.updateDataset.dataset) {
          // Start the processing of the dataset again to validate the new data
          const processingResponse = await startDatasetProcessing([
            {
              datasetId: dataset.id,
              csvSeperator: ';',
              overwriteExisting: overwriteExistingFarmData,
              clusterSites: values.clusterSites,
              ...(values.siteClusterId && { siteClusterId: values.siteClusterId }),
            },
          ]);
          if (processingResponse.data?.processDatasets.datasets.length) {
            // The cache will be updated with the new dataset
            onProcessingComplete();
            onClose?.();
            return;
          }
        }
      }
      // eslint-disable-next-line no-console
      console.error('Something went wrong updating / validating the dataset');
      onClose?.();
      onProcessingComplete();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Something went wrong updating / validating the dataset');
      onClose?.();
      onProcessingComplete();
    }
  };

  return (
    <DialogDefault open={open} title="Confirm farm clustering" onClose={onClose}>
      <Formik
        initialValues={datasetFarmClusteringSchema.default()}
        validationSchema={datasetFarmClusteringSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <DialogContent>
              {datasetClustersLoading ? (
                <Loader />
              ) : (
                <Box display="flex" flexDirection="column">
                  {hasClusters ? (
                    <Box>
                      <ThemeTypography variant="BODY_MEDIUM_BOLD">
                        Some farms of this import are already part of the following origin clusters:
                      </ThemeTypography>
                      {clusters.map(siteCluster => (
                        <CardContainer padding={1.5} mt={1} key={siteCluster.id} minWidth={500}>
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                              <Box display="flex" bgcolor="white" borderRadius="6px" padding="4px">
                                <Icon
                                  name="site-cluster-placeholder"
                                  size="xx-large"
                                  color="blue-ice"
                                />
                              </Box>
                              <Box ml={1.5}>
                                <Box display="flex" alignItems="center" gap={0.5}>
                                  <ThemeTypography variant="ITEM_TITLE">
                                    {siteCluster.title}
                                  </ThemeTypography>
                                  <Box ml={0.5} />
                                </Box>
                                <Box display="flex" alignItems="center">
                                  <Icon name="tag-filled" size="small" color="gray-60" />
                                  <Box ml={0.5} />
                                  <ThemeTypography variant="LABEL_INPUT">
                                    Origin Cluster
                                  </ThemeTypography>
                                  <Box ml={1} />
                                  <Icon name="farm" size="small" color="gray-60" />
                                  <Box ml={0.5} />
                                  <ThemeTypography autoOverflow variant="LABEL_INPUT">
                                    {siteCluster.sites.count} farms
                                  </ThemeTypography>
                                  {siteCluster.sites.count > 0 && (
                                    <>
                                      <Box ml={1} />
                                      <Icon name="raw-material" size="small" color="gray-60" />
                                      <Box ml={0.5} />
                                      <ThemeTypography autoOverflow variant="LABEL_INPUT">
                                        {
                                          (siteCluster.sites?.edges[0]?.node as IBaseFarmSite)
                                            ?.rawMaterial?.title
                                        }
                                      </ThemeTypography>
                                    </>
                                  )}

                                  <Box ml={1} />
                                  <Icon name="location" size="small" color="gray-60" />
                                  <Box ml={0.5} />
                                  <ThemeTypography autoOverflow variant="LABEL_INPUT">
                                    {siteCluster.locationName}
                                  </ThemeTypography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </CardContainer>
                      ))}
                      <DropdownContainer mt={3}>
                        <FormItem whiteBackground={Booleanish(true)}>
                          <ThemeTypography variant="BODY_MEDIUM">
                            Add all imported farms to the same origin cluster:
                          </ThemeTypography>
                          <Field name="clusterSites" fullWidth component={Switch} type="checkbox" />
                          <StyledTooltip
                            variant="INFO"
                            text="Enable this to cluster all farms of each imported dataset to an origin cluster unique per country and partner. Disable this to not cluster farms and store them individually per partner. An origin cluster is a group of sites at the origin that output the same component (e.g. a cluster of cocoa farms)."
                          />
                        </FormItem>
                        {values.clusterSites && (
                          <>
                            <Box mt={1.5} mb={0.5}>
                              <ThemeTypography variant="BODY_MEDIUM_BOLD">
                                Select cluster to add imported farms into
                              </ThemeTypography>
                            </Box>
                            <DropDown
                              size={AvailableSizes.SMALL}
                              options={dropdownOptions || []}
                              onChange={(item: IClusterSiteOption) => {
                                setFieldValue('siteClusterId', item.value);
                              }}
                              fullWidth
                              renderItems={(item: IClusterSiteOption) => (
                                <ThemeTypography variant="BODY_SMALL">{item.title}</ThemeTypography>
                              )}
                              placeholder="Select cluster"
                              currentValue={dropdownOptions.find(
                                cluster => cluster.value === values.siteClusterId
                              )}
                            />
                          </>
                        )}
                      </DropdownContainer>
                    </Box>
                  ) : (
                    <>
                      <ThemeTypography variant="BODY_MEDIUM_BOLD">
                        Do you want farms of this import to be added to an new origin cluster?
                      </ThemeTypography>
                      <Box mb={0.5} />
                      <FormItem>
                        <ThemeTypography variant="BODY_MEDIUM">
                          Add all imported farms to the same origin cluster:
                        </ThemeTypography>
                        <Field name="clusterSites" fullWidth component={Switch} type="checkbox" />
                        <StyledTooltip
                          variant="INFO"
                          text="Enable this to cluster all farms of each imported dataset to an origin cluster unique per country and partner. Disable this to not cluster farms and store them individually per partner. An origin cluster is a group of sites at the origin that output the same component (e.g. a cluster of cocoa farms)."
                        />
                      </FormItem>
                    </>
                  )}
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <Box display="flex" justifyContent="space-between" width="100%">
                <ThemeButton color="BLUE_ICE" onClick={onClose}>
                  Cancel
                </ThemeButton>
                <ThemeButton
                  type="submit"
                  disabled={
                    datasetClustersLoading ||
                    (!values.clusterSites && hasClusters) ||
                    (values.clusterSites && values.siteClusterId === undefined && hasClusters)
                  }
                  loading={isSubmitting}
                >
                  Confirm
                </ThemeButton>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </DialogDefault>
  );
};

export default ConfirmDatasetImportDialog;
