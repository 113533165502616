import { gql } from '@apollo/client';
import { RAW_MATERIAL_FRAGMENT } from './component.fragments';
import { PARTNER_REQUEST_FRAGMENT } from './partner.fragments';
import { CULTIVATED_AREAS_FRAGMENT } from './site.fragments';
import { DATASET_FRAGMENT, DATASET_TABLE_REPRESENTATION_FRAGMENT } from './dataset.fragments';

export const COMPLIANCE = gql`
  fragment compliance on Compliance {
    id
    type
    title
    description
    imageUrl
  }
`;

export const GEOC_BATCH = gql`
  fragment geocBatchValues on GeocBatch {
    id
    createdTimestamp
    modifiedTimestamp
    externalBatchId
    geocBatchId
    datasetId
    weight
    riskStatus
    harvestDate
  }
`;

export const EUDR_BASE_STATEMENT = gql`
  fragment eudrBaseStatementValues on EUDRStatement {
    id
    title
    createdTimestamp
    modifiedTimestamp
    year
    status
    companyResponsibilityType
    companyAffectednessType
    aggregatedQuestionnaires {
      aggregatedResponseSummary {
        answered
        notAnswered
        total
      }
      aggregatedRiskSummary {
        lowRiskPercentage
        mediumRiskPercentage
        highRiskPercentage
      }
      requests {
        ...partnerRequestValues
      }
    }
  }
  ${PARTNER_REQUEST_FRAGMENT}
`;

export const EUDR_STATEMENT = gql`
  fragment eudrStatementValues on EUDRStatement {
    ...eudrBaseStatementValues
    involvedPartnerCompanies {
      id
    }
    datasets {
      ...datasetValues
      cultivatedAreas {
        ...cultivatedAreaValues
      }
      tableRepresentation {
        ...datasetTableRepresentationValues
      }
    }
  }
  ${EUDR_BASE_STATEMENT}
  ${CULTIVATED_AREAS_FRAGMENT}
  ${DATASET_FRAGMENT}
  ${DATASET_TABLE_REPRESENTATION_FRAGMENT}
`;

export const GEOC_PARCEL = gql`
  fragment geocParcelValues on GeocParcel {
    id
    createdTimestamp
    geocParcelId
    harvestDate
    rawMaterialId
    cultivatedAreasId
    resultTimestamp
    deforestationRisk
    baselineDate
    landcoverPngBaselineDate
    forestCoverage
    plantationCoverage
    shrubsCoverage
    noTreesCoverage
    rgbPngBaselineDate
    rgbPngProductionEnddate
    forestChangePng
    deforestationIndex
    deforestationAreaHa
    degradationRisk
    degradationAreaHa
    degradationIndex
    countryRisk
    geocBatches {
      ...geocBatchValues
    }
    rawMaterial {
      ...rawMaterialValues
    }
    cultivatedAreas {
      ...cultivatedAreaValues
    }
  }
  ${GEOC_BATCH}
  ${RAW_MATERIAL_FRAGMENT}
  ${CULTIVATED_AREAS_FRAGMENT}
`;

export const QUESTIONNAIRE_RESPONSE_OPTION_FRAGMENT = gql`
  fragment questionnaireResponseOptionValues on QuestionnaireResponsesOptions {
    id
    text
    weight
    riskLevel
    answerPercentage
  }
`;
