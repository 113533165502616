import { useQuery } from '@apollo/client';
import { Box, styled } from '@mui/material';
import useDatasetMutation from 'components/ComponentsLibrary/hooks/useDatasetMutation';
import { Loader } from 'components/Forms';
import { ActionContainer, ErrorState } from 'components/Structure';
import { useDialog } from 'components/hooks';
import { ThemeButton } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { GET_DATASETS } from 'graphql/queries/dataset.queries';
import { GET_BASE_PARTNER_COMPANY } from 'graphql/queries/partner.queries';
import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IDataset, IDatasetsResponse } from 'types/dataset.types';
import { IBasePartnerCompany } from 'types/partner.types';
import { removeGraphConnections } from 'utils/graphql.utils';
import DataImportOverviewCard from '../DataImportOverviewCard';
import DatasetDetailsCard from '../DatasetDetailsCard';
import DatasetOverviewCard from '../DatasetOverviewCard';
import { DATA_IMPORT_CATEGORY_CONFIG } from '../constants/dataImportConfig';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';

const Container = styled('div')(() => ({
  position: 'relative',
  minHeight: 50,
}));

const DatasetContainer = styled(Box)(() => ({
  flexWrap: 'wrap',
  display: 'flex',
}));

const GeoDataImport: FC = () => {
  const { id: partnerId } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const { deleteDataset: deleteEUDRDataset } = useDatasetMutation();
  const { openDialog } = useDialog();
  const { company } = useCompanyPlanContext();
  const { data, loading, error } = useQuery<IDatasetsResponse>(GET_DATASETS, {
    variables: {
      filters: { ownedById: partnerId || company.id },
    },
  });
  const { data: partnerData } = useQuery<{ partner: IBasePartnerCompany }>(
    GET_BASE_PARTNER_COMPANY,
    {
      variables: {
        id: partnerId,
      },
      skip: !partnerId,
    }
  );
  const [datasetDetails, setDatasetDetails] = useState<IDataset>();

  const datasets = data ? removeGraphConnections(data.datasets) : [];
  const categoryConfig = DATA_IMPORT_CATEGORY_CONFIG[0];

  const handleClickUpload = () => {
    openDialog({ type: 'DATASET_IMPORT', props: { ownedBy: partnerData?.partner || company } });
  };

  const handleClickViewDataset = (datasetId: string) => navigate(`dataset/${datasetId}`);

  const handleClickDeleteDataset = (dataset: IDataset) => {
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Delete dataset',
        itemTitle: datasets.find(item => item.id === dataset.id)?.title,
        text: 'Are you sure you want to delete this dataset? All information will be lost and you will not be able to get this information back.',
        onSubmit: async () => {
          await deleteEUDRDataset(dataset);
          setDatasetDetails(undefined);
        },
        submitText: 'Delete',
      },
    });
  };

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <ErrorState />;
  }

  return (
    <Container>
      {datasets.length > 0 ? (
        <>
          <ActionContainer>
            <ThemeButton
              size="medium"
              color="YELLOW"
              startIcon={<Icon name="plus" />}
              onClick={handleClickUpload}
            >
              Add new dataset
            </ThemeButton>
          </ActionContainer>
          <Box mt={4}>
            <Box gap={2} display="flex" justifyContent="space-between">
              <DatasetContainer gap={2}>
                {datasets.map(dataset => (
                  <DatasetOverviewCard
                    key={dataset.id}
                    dataset={dataset}
                    selected={datasetDetails?.id === dataset.id}
                    onClick={() => setDatasetDetails(dataset)}
                  />
                ))}
              </DatasetContainer>
              {datasetDetails !== undefined && (
                <DatasetDetailsCard
                  dataset={datasetDetails}
                  buttonText="View farms"
                  onClickDataset={handleClickViewDataset}
                  onDeleteDataset={handleClickDeleteDataset}
                />
              )}
            </Box>
          </Box>
        </>
      ) : (
        <DataImportOverviewCard
          title={`Add your ${categoryConfig.dataLabel} geo-data to enable tracing of impact along your supply chains`}
          onAddTableClick={handleClickUpload}
        />
      )}
    </Container>
  );
};

export default GeoDataImport;
