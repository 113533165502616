import { useMutation } from '@apollo/client';
import { useMessages } from 'components/hooks';
import { CREATE_SITE, DELETE_SITE, UPDATE_SITE } from 'graphql/mutations/site.mutations';
import { ICreateSiteInput, IUpdateSiteInput } from 'graphql/mutations/types/site-mutation.types';
import { AvailableLanguagesType } from 'types/enums';
import { ISite } from 'types/site.types';

const useSiteMutations = () => {
  const { setSuccessMessage, setErrorMessage } = useMessages();
  const [createSite, { loading: loadingCreate }] = useMutation<
    { createSite: { site: ISite } },
    { input: ICreateSiteInput; lang?: AvailableLanguagesType }
  >(CREATE_SITE, {
    onCompleted: site => {
      setSuccessMessage('Site successfully created');
    },
    onError: error => {
      if (
        error.graphQLErrors.length &&
        error.graphQLErrors.findIndex(
          ({ message }) => message === 'SITE_DUPLICATED_EXTERNAL_ID'
        ) !== -1
      ) {
        setErrorMessage(
          'The site id already exists in the system. The id has to be unique across the platform.'
        );
      } else {
        setErrorMessage('There was an error creating your site');
      }
    },
    refetchQueries: ['GetSites'],
  });

  const [updateSite, { loading: loadingUpdate }] = useMutation<
    { updateSite: { site: ISite } },
    { id: string; input: IUpdateSiteInput; lang?: AvailableLanguagesType }
  >(UPDATE_SITE, {
    onCompleted: () => {
      setSuccessMessage('Site successfully updated');
    },
    onError: error => {
      if (
        error.graphQLErrors.length &&
        error.graphQLErrors.findIndex(
          ({ message }) => message === 'SITE_DUPLICATED_EXTERNAL_ID'
        ) !== -1
      ) {
        setErrorMessage(
          'The site id already exists in the system. The id has to be unique across the platform.'
        );
      } else if (error.message) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('There was an error updating your site');
      }
    },
    refetchQueries: ['GetSites'],
  });

  const [deleteSite, { loading: loadingDelete }] = useMutation<
    { deleteSite: { id: string } },
    { id: string }
  >(DELETE_SITE, {
    onCompleted: () => {
      setSuccessMessage('Site successfully deleted');
    },
    onError: () => setErrorMessage('There was an error deleting your site'),
    refetchQueries: ['GetSites'],
  });

  return {
    loading: loadingCreate || loadingUpdate || loadingDelete,
    createSite,
    updateSite,
    deleteSite,
  };
};

export default useSiteMutations;
