import { MapStyle } from '../CustomMap';

export const DEFAULT_ZOOM_TO_LOCATION_LEVEL = 6;

export const MAX_AUTO_ZOOM_LEVEL = 16;

/**
 * Maximum animation time in seconds
 * Will skip the animation if the time is greater than this value
 * */
export const MAX_FOCUS_ANIMATION_TIME = 5_000;

export const MAP_STYLES: Record<MapStyle, string> = {
  default: 'mapbox://styles/seedtrace/clia0bhp5004l01pghikh13gg',
  satellite: 'mapbox://styles/seedtrace/clon0fll500bi01nzfrjgfbs9',
  outdoors: 'mapbox://styles/seedtrace/clxbr71kg02ah01pn1izlaoxp',
};
