import { useMutation, useQuery } from '@apollo/client';
import { Box, Grid, styled } from '@mui/material';
import { Plus } from '@styled-icons/bootstrap';
import EmptyGridStateIcon from 'assets/img/empty-grid-state.svg';
import DataImportOverviewCard from 'components/DataImport/DataImportOverviewCard';
import { DATA_IMPORT_CATEGORY_CONFIG } from 'components/DataImport/constants/dataImportConfig';
import { ActionContainer, PageSubTitle } from 'components/Structure';
import FlexBox from 'components/Structure/FlexBox';
import { useDialog, useMessages } from 'components/hooks';
import { ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { DELETE_DATA_IMPORT } from 'graphql/mutations/import.mutations';
import { GET_FIRST_MILE_IMPORTS } from 'graphql/queries/import.queries';
import React, { FC, useState } from 'react';
import Moment from 'react-moment';
import { useNavigate, useParams } from 'react-router-dom';
import { Booleanish } from 'types/booleanish.types';
import { FirstMileImport, IFirstMileImportsResponse } from 'types/dataImport.types';
import {
  DataItem,
  DetailsCard,
  DatasetDate,
  DatasetTitle,
} from 'components/DataImport/DataImport.styles';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';

const Container = styled('div')(() => ({
  position: 'relative',
  minHeight: 50,
}));

const DatasetContainer = styled(Box)(() => ({
  flexWrap: 'wrap',
  display: 'flex',
}));

const FarmImport: FC = () => {
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const { setSuccessMessage, setErrorMessage } = useMessages();
  const { id: companyId } = useParams<{ id?: string }>();
  const { company } = useCompanyPlanContext();

  const [importDetails, setImportDetails] = useState<FirstMileImport>();

  const { data, loading } = useQuery<IFirstMileImportsResponse>(GET_FIRST_MILE_IMPORTS, {
    variables: { companyId: companyId || company.id },
  });

  const [deleteDataImport] = useMutation(DELETE_DATA_IMPORT, {
    onCompleted: () => {
      setSuccessMessage('Data import successfully deleted');
    },
    onError: () => {
      setErrorMessage('Failed to delete data import');
    },
    refetchQueries: [GET_FIRST_MILE_IMPORTS],
  });

  const categoryConfig = DATA_IMPORT_CATEGORY_CONFIG[0];

  const startEditingTable = (importId?: string) => {
    const basePath = companyId
      ? `/partners/${companyId}/imports/${categoryConfig.key}`
      : `/imports/${categoryConfig.key}`;
    navigate(`${basePath}${importId ? `/${importId}` : ''}`);
  };

  const handleDeleteImport = async (id: string) => {
    await deleteDataImport({ variables: { id } });
    setImportDetails(undefined);
  };

  const handleDeleteImportClick = () => {
    if (!importDetails) {
      // eslint-disable-next-line no-console
      console.log('No import details to delete');
      return;
    }
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Delete data import',
        itemTitle: importDetails.title || 'Unnamed',
        text: 'Are you sure you want to delete this data import? The imported data will still be available in the system.',
        cancelText: 'Cancel',
        onSubmit: () => handleDeleteImport(importDetails.id),
        onCancel: () => undefined,
      },
    });
  };

  return (
    <Container>
      {!loading && data && data?.firstMileImports.edges.length > 0 ? (
        <>
          <ActionContainer>
            <ThemeButton
              size="medium"
              color="YELLOW"
              startIcon={<Plus size={20} />}
              onClick={() => startEditingTable()}
            >
              Add new data set
            </ThemeButton>
          </ActionContainer>
          <Box mt={4}>
            <Box gap={2} display="flex" justifyContent="space-between">
              <DatasetContainer gap={2}>
                {data.firstMileImports.edges.map(({ node }) => (
                  <Grid item key={node.id} xs={6} sm={4} md={3} lg={2}>
                    <DataItem
                      selected={Booleanish(importDetails?.id === node.id)}
                      onClick={() => setImportDetails(node)}
                    >
                      <FlexBox justifyContent="center" mb={2}>
                        <img width={100} src={EmptyGridStateIcon} alt="empty" />
                      </FlexBox>
                      {node.title ? (
                        <DatasetTitle>{node.title}</DatasetTitle>
                      ) : (
                        <DatasetTitle unnamed>Unnamed</DatasetTitle>
                      )}
                      <DatasetDate>
                        <Moment format="L">{node.createdTimestamp}</Moment>
                      </DatasetDate>
                    </DataItem>
                  </Grid>
                ))}
              </DatasetContainer>
              {importDetails !== undefined && (
                <DetailsCard>
                  <PageSubTitle title="Details" />
                  <Box mb={1} />
                  <ThemeTypography variant="BODY_MEDIUM_BOLD">
                    {importDetails.title || 'Unnamed'}
                  </ThemeTypography>
                  <FlexBox mb={2}>
                    <ThemeTypography variant="BODY_MEDIUM">
                      Created <Moment format="LL">{importDetails.createdTimestamp}</Moment>
                    </ThemeTypography>
                  </FlexBox>
                  <FlexBox>
                    <ThemeButton
                      color="BLUE_ICE"
                      startIcon={<Icon name="show" />}
                      onClick={() => startEditingTable(importDetails?.id)}
                    >
                      View
                    </ThemeButton>
                    <Box ml={2} />
                    <ThemeButton
                      color="BLUE_ICE"
                      startIcon={<Icon name="delete" />}
                      onClick={handleDeleteImportClick}
                    >
                      Delete
                    </ThemeButton>
                  </FlexBox>
                </DetailsCard>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <DataImportOverviewCard
          title={`Add your ${categoryConfig.dataLabel} data to enable tracing of impact along your supply chains`}
          onAddTableClick={startEditingTable}
        />
      )}
    </Container>
  );
};

export default FarmImport;
