import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import {
  CSV_SEPARATOR_OPTIONS,
  DECIMAL_SEPARATOR_OPTIONS,
} from 'components/ComplianceGuide/utils/eudrCompliance.utils';
import useDatasetMutation from 'components/ComponentsLibrary/hooks/useDatasetMutation';
import { Loader } from 'components/Forms';
import { useMessages, useUploadState } from 'components/hooks';
import {
  IGeoUploadFormValue,
  IGeoUploadFormValues,
  geoUploadDatasetSchema,
  geoUploadSchema,
} from 'constants/schemas/geoUpload.schema';
import { Form, Formik, FormikProps } from 'formik';
import { IProcessDatasetInput } from 'graphql/mutations/types/dataset-mutation.types';
import React, { FC, useMemo, useRef, useState } from 'react';
import { DatasetStatus } from 'types/dataset.types';
import FormikTabs from '../../FormikTabs';
import DatasetUploadForm from '../../Forms/DatasetUploadForm';
import { Box } from '@mui/material';

const UploadGeoData: FC = () => {
  const formRef = useRef<FormikProps<IGeoUploadFormValues>>(null);
  const { setErrorMessage } = useMessages();
  const { statement, setFormData, setSelectedSubSection } = useEudrComplianceGuide();
  const { createDatasets, deleteDataset, startDatasetProcessing, updateDatasets } =
    useDatasetMutation();
  const { handleCleanCreatedRecords, cleanFiles } = useUploadState();

  const [selectedDatasetIndex, setSelectedDatasetIndex] = useState<number>(0);

  // Ideally we set the initial values based on the statement, but ths is currently not since we would need to download the files again and put them back into the upload context
  // This is a huge issue since since now we need to make sure to delete datasets if something goes wrong otherwise we have orphaned datasets on the statement that you can not delete
  const initialValues: IGeoUploadFormValues = useMemo(
    () =>
      statement?.datasets?.length
        ? statement.datasets.map(dataset => ({
            ...geoUploadDatasetSchema.default(),
            datasetId: dataset.id,
            title: dataset.title,
            rawMaterialId: dataset.rawMaterial.id,
            countryCode: dataset.originCountry,
            ownedBy: dataset.ownedBy,
            allowMultipleFiles: false,
            fileConfiguration: {
              csvSeparator: undefined,
              decimalSeparator: undefined,
            },
            files: dataset.documents.map(document => ({
              id: document.id,
              title: document.title,
              inUploadContext: false,
            })),
          }))
        : geoUploadSchema.default(),
    [statement]
  );

  if (!statement) {
    return <Loader />;
  }

  const handleSubmit = async (values: IGeoUploadFormValues) => {
    // Do not change view updating the datasets in the statement
    setFormData({ doNotChangeSection: true });

    // Create the EUDR datasets that were not created and update existing datasets
    const datasetCreationResults = await createDatasets(
      values.filter(({ datasetId }) => !datasetId), // The datasets were not created yet
      statement.id
    );
    const datasetUpdateResults = await updateDatasets(
      values.filter(({ datasetId }) => !!datasetId) as (IGeoUploadFormValue & {
        datasetId: string;
      })[] // The value were datasetId are set were already created
    );

    try {
      // Build unique array with all existing dataset ids
      const datasetIds = [
        ...datasetCreationResults.map(result => result.data?.createDataset.dataset.id),
        ...datasetUpdateResults.map(result => result.data?.updateDataset.dataset.id),
      ].filter(datasetId => !!datasetId) as string[];

      const inputs: IProcessDatasetInput[] = datasetIds.map(id => {
        const fileConfig = values.find(({ datasetId }) => datasetId === id)?.fileConfiguration;
        return {
          datasetId: id,
          decimalSeperator:
            (fileConfig?.decimalSeparator &&
              DECIMAL_SEPARATOR_OPTIONS[fileConfig.decimalSeparator].value) ||
            undefined,
          csvSeperator:
            (fileConfig?.csvSeparator && CSV_SEPARATOR_OPTIONS[fileConfig.csvSeparator].value) ||
            undefined,
        };
      });

      const response = await startDatasetProcessing(inputs);

      if (response.errors?.length) {
        formRef.current?.setSubmitting(false);
        setErrorMessage(response.errors[0].message);
      } else if (
        response.data?.processDatasets.datasets.some(
          ({ status }) => status === DatasetStatus.MAPPING_FAILED
        ) &&
        response.data?.processDatasets.datasetMappings?.length
      ) {
        setFormData({ datasetMappings: response.data?.processDatasets.datasetMappings });
        setSelectedSubSection('map-columns');
      } else if (
        response.data?.processDatasets?.datasets.some(
          ({ status, tableRepresentation }) =>
            status === DatasetStatus.VALIDATION_FAILED && !!tableRepresentation
        )
      ) {
        // Skip the mapping and process with validation
        setSelectedSubSection('validate');
      } else if (
        response.data?.processDatasets?.datasets.every(
          ({ status }) => status === DatasetStatus.NORMALISED || status === DatasetStatus.COMPLETED
        )
      ) {
        // Allow changing the section if parsing, mapping and validation was successful and all sub sections were skipped
        setFormData({ doNotChangeSection: false });
        setSelectedSubSection('confirmation');
      }
    } finally {
      formRef.current?.setSubmitting(false);
      handleCleanCreatedRecords();
      cleanFiles();
    }
  };

  return (
    <Formik<IGeoUploadFormValues>
      innerRef={formRef}
      validationSchema={geoUploadSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ isValid, errors, isSubmitting, submitForm }) => (
        <Form>
          <EudrComplianceGuideSubSection
            allowNextStepNavigation={isValid && !errors.length}
            nextStepLoading={isSubmitting}
            customNextStepButton={{ text: 'Upload & convert' }}
            onNextStepClick={submitForm}
          >
            <FormikTabs
              selectedIndex={selectedDatasetIndex}
              onChange={setSelectedDatasetIndex}
              onDeleteDataset={deleteDataset}
            />

            <Box mb={4}>
              <DatasetUploadForm datasetIndex={selectedDatasetIndex} />
            </Box>
          </EudrComplianceGuideSubSection>
        </Form>
      )}
    </Formik>
  );
};

export default UploadGeoData;
