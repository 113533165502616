import React, { FC, MouseEvent } from 'react';
import { Box, IconButton, TableCell, Tooltip } from '@mui/material';
import { Trash } from '@styled-icons/bootstrap';
import { PencilSquare } from '@styled-icons/bootstrap/PencilSquare';
import {
  StyledIconButton,
  IconContainer,
  StyledCell,
  TableRow,
  Date,
} from 'components/Partners/PartnersTable/PartnersTableBody/PartnersTableBody.styles';
import { HeaderColumn } from 'types/types';
import Moment from 'react-moment';
import { SitesTableColumnKeys } from './sitesHeaderColumns';
import { ThemeTypography } from 'designSystem';
import { PartnerPlaceholderImage } from 'assets/img';
import { IBaseFarmSite, ISite, SiteType } from 'types/site.types';
import Image from 'designSystem/DataDisplay/Image/Image';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { SiteLabelKeys } from 'utils/site.utils';
import { Booleanish } from 'types/booleanish.types';
import { FlexBox } from 'components/Structure';

interface ISiteTableRowProps {
  site: ISite;
  headerColumns: HeaderColumn[];
  /**
   * @deprecated
   * Workaround to fix the different width of the tables inside the inner cluster
   */
  fixedSize?: boolean;
  onRowClick?: (site: ISite) => void;
  onEdit?: (site: ISite) => void;
  onDelete?: (siteId: string) => void;
  onEditCultivatedArea?: (site: ISite) => void;
}

const SiteTableRow: FC<ISiteTableRowProps> = ({
  headerColumns,
  site,
  fixedSize,
  onEdit,
  onRowClick,
  onDelete,
  onEditCultivatedArea,
}) => {
  return (
    <TableRow
      data-cy="site-table-row"
      disabled={Booleanish(!onRowClick)}
      onClick={() => onRowClick?.(site)}
    >
      {headerColumns.map(({ id: columnId, align }) => {
        switch (columnId) {
          case SitesTableColumnKeys.TITLE:
            return (
              <StyledCell
                key={`${site.id}-${columnId}-title`}
                scope="row"
                align={align}
                width={fixedSize ? '54.5%' : undefined}
              >
                <FlexBox width="100%" gap={2}>
                  {!!site.image ? (
                    <Image width={44} height={44} image={site.image} />
                  ) : (
                    <Box display="flex" bgcolor="white" borderRadius="6px" padding="4px">
                      <Icon name="site-placeholder" size="xx-large" color="blue-ice" />
                    </Box>
                  )}

                  <Box width="calc(100% - 60px)">
                    <FlexBox>
                      <ThemeTypography variant="ITEM_TITLE" autoOverflow>
                        {site.title}
                      </ThemeTypography>
                      {site.externalId && (
                        <Box ml={0.8} maxWidth="30%">
                          <ThemeTypography variant="BODY_MEDIUM" autoOverflow color="GRAY_80">
                            | {site.externalId}
                          </ThemeTypography>
                        </Box>
                      )}
                    </FlexBox>

                    <FlexBox>
                      <Icon name="tag-filled" size="small" color="gray-60" />
                      <Box ml={0.5} />
                      <ThemeTypography variant="LABEL_INPUT">
                        {SiteLabelKeys[site.siteType]}
                      </ThemeTypography>
                      <Box ml={1} />
                      {site.siteType === SiteType.FARM && (
                        <>
                          <Icon name="raw-material" size="small" color="gray-60" />
                          <Box ml={0.5} />
                          <ThemeTypography variant="LABEL_INPUT" autoOverflow>
                            {(site as IBaseFarmSite).rawMaterial?.title}
                          </ThemeTypography>
                          <Box ml={1} />
                        </>
                      )}
                      <Icon name="location" size="small" color="gray-60" />
                      <Box ml={0.5} />
                      <ThemeTypography variant="LABEL_INPUT" autoOverflow maxWidth={200}>
                        {site.locationName}
                      </ThemeTypography>
                    </FlexBox>
                  </Box>
                </FlexBox>
              </StyledCell>
            );
          case SitesTableColumnKeys.CREATOR:
            return (
              <StyledCell
                key={`${site.id}-${columnId}-creator`}
                scope="row"
                align={align}
                width={fixedSize ? '21.5%' : undefined}
              >
                {site.createdBy && (
                  <Box display="flex" alignItems="center">
                    <Image
                      width={44}
                      height={44}
                      image={site.createdBy?.logo}
                      backupImageUrl={PartnerPlaceholderImage}
                    />
                    <Box ml={1}>
                      <ThemeTypography variant="ITEM_SUBTITLE">
                        {site.createdBy?.name}
                      </ThemeTypography>
                    </Box>
                  </Box>
                )}
              </StyledCell>
            );
          case SitesTableColumnKeys.OWNER:
            return (
              <StyledCell
                key={`${site.id}-${columnId}-owner`}
                scope="row"
                align={align}
                width={fixedSize ? '21.5%' : undefined}
              >
                {site.ownedBy && (
                  <Box display="flex" alignItems="center">
                    <Image
                      width={44}
                      height={44}
                      image={site.ownedBy?.logo}
                      backupImageUrl={PartnerPlaceholderImage}
                    />
                    <Box ml={1}>
                      <ThemeTypography variant="ITEM_SUBTITLE">
                        {site.ownedBy?.name}
                      </ThemeTypography>
                    </Box>
                  </Box>
                )}
              </StyledCell>
            );
          case SitesTableColumnKeys.MODIFIED_TIMESTAMP:
            return (
              <StyledCell key={`${site.id}-${columnId}-created-at`} scope="row" align={align}>
                <Date>
                  <Moment format="MMM DD, YYYY">{site.modifiedTimestamp}</Moment>
                </Date>
              </StyledCell>
            );
          case SitesTableColumnKeys.ACTIONS:
            return (
              <TableCell
                key={`${site.id}-${columnId}-actions`}
                width={100}
                scope="row"
                align={align}
              >
                <IconContainer>
                  {!!onEditCultivatedArea && site.siteType === SiteType.FARM && (
                    <Tooltip title="Edit cultivation area" placement="top">
                      <IconButton
                        data-testid="edit-site-cultivation-area"
                        aria-label="edit cultivation area"
                        onClick={event => {
                          event.stopPropagation();
                          onEditCultivatedArea(site);
                        }}
                      >
                        <Icon name="polygon-mapping" size="small" color="gray-100" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!!onEdit && (
                    <Tooltip title="Edit" placement="top">
                      <StyledIconButton
                        data-testid="edit-site"
                        aria-label="edit"
                        onClick={(event: MouseEvent) => {
                          event.stopPropagation();
                          onEdit(site);
                        }}
                      >
                        <PencilSquare size={14} />
                      </StyledIconButton>
                    </Tooltip>
                  )}
                  <Tooltip
                    title={
                      site.inUse
                        ? 'Site is used on an activity. Remove the site on the activity first to be able to delete.'
                        : 'Delete'
                    }
                    placement="top"
                  >
                    <span>
                      <StyledIconButton
                        aria-label="delete"
                        data-testid="delete-site"
                        disabled={site.inUse}
                        onClick={(event: MouseEvent) => {
                          event.stopPropagation();
                          onDelete?.(site.id);
                        }}
                      >
                        <Trash size={14} />
                      </StyledIconButton>
                    </span>
                  </Tooltip>
                </IconContainer>
              </TableCell>
            );
          default:
            return <></>;
        }
      })}
    </TableRow>
  );
};

export default SiteTableRow;
