import ExcelTable, { IExcelTableProps } from 'components/DataImport/DataImportTable/ExcelTable';
import HeaderColumn from 'components/DataImport/DataImportTable/HeaderColumn';
import { ColDefWithValidator } from 'components/DataImport/DataImportTable/excelTable.types';
import { createColumnDefinition } from 'components/DataImport/utils/dataImport.utils';
import {
  createPositiveNumberValidator,
  validateCoordinates,
  validateNotEmpty,
  validateUniqueness,
} from 'components/DataImport/utils/dataImport.validators';
import React, { FC, useMemo } from 'react';
import { DatasetTableRepresentation } from 'types/dataset.types';
import { v4 as uuid } from 'uuid';

interface IDatasetValidationFormProps {
  tableRepresentation: DatasetTableRepresentation;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mode: IExcelTableProps<any, unknown>['mode'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gridRef?: IExcelTableProps<any, unknown>['gridRef'];
  height?: number;
}

type TableCell = {
  value?: string;
  error?: string;
  isValid?: boolean;
};

type TableRowData = {
  farmName: TableCell;
  farmId: TableCell;
  coordinates: TableCell;
  farmSize?: TableCell;
};

export const COLUMN_DEFINITIONS: ColDefWithValidator<TableRowData>[] = [
  createColumnDefinition('farmId', () => <HeaderColumn title="Farm Id" />, validateUniqueness),
  createColumnDefinition('farmName', () => <HeaderColumn title="Farm name" />, validateNotEmpty),
  createColumnDefinition(
    'coordinates',
    () => <HeaderColumn title="Coordinates (Lat,Lng)" />,
    validateCoordinates
  ),
  createColumnDefinition(
    'farmSize',
    () => <HeaderColumn title="Size (km²)" />,
    createPositiveNumberValidator('Farm size needs to be a number, in e.g. square km.')
  ),
];

const DatasetValidationForm: FC<IDatasetValidationFormProps> = ({
  gridRef,
  mode,
  tableRepresentation,
  height,
}) => {
  const shownColumnKeys = useMemo(
    () => [
      'farmId',
      'coordinates',
      'name',
      ...(tableRepresentation.columnsNames.includes('size') ? ['size'] : []),
    ],
    [tableRepresentation.columnsNames]
  );

  const rowData = useMemo(() => {
    return tableRepresentation.rows.map(row => ({
      id: uuid(),
      farmName: {
        value: row.farmName.value,
        isValid: !row.farmName.error?.length,
        errorMessage: row.farmName.error?.[0].errorMessage,
      },
      farmId: {
        value: row.farmId.value,
        isValid: !row.farmId.error?.length,
        errorMessage: row.farmId.error?.[0].errorMessage,
      },
      coordinates: {
        value: row.coordinates.value,
        isValid: !row.coordinates.error?.length,
        errorMessage: row.coordinates.error?.[0].errorMessage,
      },
      ...(row.farmSize
        ? {
            size: {
              value: row.farmSize.value,
              isValid: !row.farmSize.error?.length,
              errorMessage: row.farmSize.error?.[0].errorMessage,
            },
          }
        : {}),
    }));
  }, [tableRepresentation]);

  const columns = useMemo(
    () => COLUMN_DEFINITIONS.filter(({ field }) => field && shownColumnKeys.includes(field)),
    [shownColumnKeys]
  );

  return (
    <ExcelTable<TableRowData>
      mode={mode}
      height={height}
      gridRef={gridRef}
      rowData={rowData}
      columnDefs={columns}
    />
  );
};

export default DatasetValidationForm;
