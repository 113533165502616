import { Box, Grid } from '@mui/material';
import { ArrowRightShort } from '@styled-icons/bootstrap';
import { FieldWrapper } from 'designSystem';
import { useField } from 'formik';
import React, { FC, useEffect, useMemo } from 'react';
import IncomingLinkSelectionField from './IncomingLinkSelectionField';
import { ShadowedFieldBlock } from './styles';
import { IChainActivity, IChainStep, ISubChain } from 'types/chain.types';

interface ILinkConnectionsBlockProps {
  steps: IChainStep[];
  activities: IChainActivity[];
  subChains: ISubChain[];
}

const LinkConnectionsBlock: FC<ILinkConnectionsBlockProps> = ({ steps, activities, subChains }) => {
  const [{ value: id }] = useField<string | undefined>('id');
  const [{ value: chainStep }] = useField<IChainActivity['chainStepId'] | undefined>('chainStepId');
  const [{ value: incomingLinks }, , helper] = useField<
    IChainActivity['incomingLinks'] | undefined
  >('incomingLinks');

  const handleValueChange = (incomingConnections: string[]) => {
    helper.setValue(
      incomingConnections.map(chainStepActivitySourceId => ({
        chainStepActivitySourceId,
      }))
    );
  };

  const currentStepIndex = steps.findIndex(step => step.id === chainStep);

  const linkableSteps = useMemo(() => {
    const currentStepIndex = steps.findIndex(step => step.id === chainStep);
    return steps.filter((_, index) => index < currentStepIndex);
  }, [steps, chainStep]);

  const linkedActivities = useMemo(
    () => incomingLinks?.map(({ chainStepActivitySourceId }) => chainStepActivitySourceId) || [],
    [incomingLinks]
  );

  /**
   * All activities that are part of a component chain and can not be linked to this activity as an incoming link
   * because they are not a outputStepActivity
   */
  const excludedSubChainActivities = useMemo(
    () =>
      activities.filter(
        ({ id, chainStepId }) =>
          steps.findIndex(({ id }) => chainStepId === id) === -1 &&
          !subChains.some(({ outputStepActivitiesIds }) => outputStepActivitiesIds.includes(id))
      ),
    [activities, steps, subChains]
  );

  /**
   * Filter activities that are in a previous step or are already used as an incoming link
   */
  const linkableActivities = useMemo(() => {
    if (currentStepIndex <= 0) {
      return [];
    }

    return activities.filter(activity => {
      const activityStepIndex = activity.pointPosition.x;
      return (
        activityStepIndex < currentStepIndex &&
        !excludedSubChainActivities.some(({ id }) => id === activity.id) &&
        id !== activity.id // Exclude the current activity from the list of linkable activities
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities, id, linkableSteps, currentStepIndex, excludedSubChainActivities]);

  /**
   * If the chain step changed, check if the current incoming links are still valid
   */
  useEffect(() => {
    if (!incomingLinks?.length) {
      return;
    }
    helper.setValue(
      incomingLinks.filter(
        ({ chainStepActivitySourceId }) =>
          linkableActivities.findIndex(({ id }) => id === chainStepActivitySourceId) !== -1
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkableSteps, linkableActivities]);

  // If this activity has no linkable activities, this content block will not be displayed
  if (!linkableSteps || !linkableActivities.length) {
    return <></>;
  }

  return (
    <ShadowedFieldBlock container>
      <Grid item xs={12}>
        <FieldWrapper
          label="Incoming connections"
          tooltip={{
            variant: 'INFO',
            helperText:
              'Select what activity that comes before this activity in the component chain. This helps you trace and map the flow of goods.',
          }}
          fullWidth
        >
          <Box display="flex" alignItems="center" gap={1.5}>
            <ArrowRightShort size={30} />
            <IncomingLinkSelectionField
              linkableSteps={linkableSteps}
              linkedActivities={linkedActivities}
              linkableActivities={linkableActivities}
              onChange={handleValueChange}
            />
          </Box>
        </FieldWrapper>
      </Grid>
    </ShadowedFieldBlock>
  );
};

export default LinkConnectionsBlock;
