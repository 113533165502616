import { FetchResult, useMutation } from '@apollo/client';
import { IGeoUploadFormValue, IGeoUploadFormValues } from 'constants/schemas/geoUpload.schema';
import { EUDR_STATEMENT } from 'graphql/fragments/compliance.fragments';
import { DELETE_DOCUMENTS } from 'graphql/mutations';
import { SUBMIT_EUDR_RISK_ANALYSIS } from 'graphql/mutations/compliance.mutations';
import {
  CREATE_DATASET,
  DELETE_DATASET,
  PROCESS_DATASETS,
  UPDATE_DATASET,
} from 'graphql/mutations/dataset.mutations';
import { ISubmitEUDRRiskAnalysisInput } from 'graphql/mutations/types/compliance-mutation.types';
import {
  ICreateDatasetInput,
  IProcessDatasetInput,
  IUpdateDatasetInput,
} from 'graphql/mutations/types/dataset-mutation.types';
import { GET_STATEMENT } from 'graphql/queries/compliance.queries';
import { GET_DATASETS } from 'graphql/queries/dataset.queries';
import { useMatch, useParams } from 'react-router-dom';
import { EudrStatement } from 'types/compliance.types';
import { IDataset, IProcessDatasetsPayload } from 'types/dataset.types';
import { RouteParamsWithId } from 'types/router.types';
import { GraphQlConnection } from 'types/types';

const useDatasetMutation = () => {
  // Can be statmentId (compliance section) or partnerId (partner library/partner ui)
  const { id } = useParams<RouteParamsWithId>();
  const isCompliance = !!useMatch('/compliance/*');
  const [deleteDocument] = useMutation(DELETE_DOCUMENTS);

  const [_createDataset] = useMutation<
    { createDataset: { dataset: IDataset } },
    { input: ICreateDatasetInput }
  >(CREATE_DATASET, {
    update: (cache, { data }) => {
      if (data?.createDataset.dataset) {
        const newDataset = data.createDataset.dataset;
        if (id && isCompliance) {
          const statementCacheKey = cache.identify({
            __typename: 'EUDRStatement',
            id: id,
          });
          const statement = cache.readFragment<EudrStatement>({
            id: statementCacheKey,
            fragment: EUDR_STATEMENT,
            fragmentName: 'eudrStatementValues',
          });
          cache.writeQuery({
            query: GET_STATEMENT,
            data: {
              statement: {
                ...statement,
                eudrDatasets: statement?.datasets.length
                  ? [...statement.datasets, newDataset]
                  : [newDataset],
              },
            },
          });
        }
      }
    },
  });

  const [_updateDataset] = useMutation<
    { updateDataset: { dataset: IDataset } },
    { id: string; input: IUpdateDatasetInput }
  >(UPDATE_DATASET, {
    // update: (cache, { data }) => {
    //   if (data?.updateEUDRDataset.eudrDataset) {
    //   const eudrDatasetCacheKey = cache.identify({
    //     __typename: 'EUDRDataset',
    //     id: data.updateEUDRDataset.eudrDataset.id,
    //   });
    //   const existingDataset = cache.readFragment<IDataset>({
    //     id: eudrDatasetCacheKey,
    //     fragment: EUDR_DATASET,
    //     fragmentName: 'datasetValues',
    //   });
    //   cache.writeFragment({
    //     id: eudrDatasetCacheKey,
    //     fragment: EUDR_DATASET,
    //     fragmentName: 'datasetValues',
    //     data: {
    //       ...existingDataset,
    //       documents: existingDataset?.documents.filter(
    //         ({ id }) => !data?.deleteDocument.ids.includes(id)
    //       ),
    //     },
    //   });
    // }
    // },
  });

  const [_deleteDataset] = useMutation<{ deleteDataset: { id: string } }, { id: string }>(
    DELETE_DATASET,
    {
      update: (cache, { data }) => {
        if (data?.deleteDataset.id) {
          if (id && isCompliance) {
            const statementCacheKey = cache.identify({
              __typename: 'EUDRStatement',
              id: id,
            });
            const statement = cache.readFragment<EudrStatement>({
              id: statementCacheKey,
              fragment: EUDR_STATEMENT,
              fragmentName: 'eudrStatementValues',
            });
            cache.writeQuery({
              query: GET_STATEMENT,
              data: {
                statement: {
                  ...statement,
                  eudrDatasets: statement?.datasets.filter(
                    dataset => dataset.id !== data.deleteDataset.id
                  ),
                },
              },
            });
          } else {
            const datasets = cache.readQuery<{ datasets: GraphQlConnection<IDataset> }>({
              query: GET_DATASETS,
              variables: { filters: { ownedById: id } },
            })?.datasets;
            cache.writeQuery({
              query: GET_DATASETS,
              variables: { filters: { ownedById: id } },
              data: {
                datasets: {
                  ...datasets,
                  edges: datasets?.edges.filter(edge => edge.node.id !== data.deleteDataset.id),
                },
              },
            });
          }
        }
      },
    }
  );

  const [_processDatasets] = useMutation<
    { processDatasets: IProcessDatasetsPayload },
    { inputs: IProcessDatasetInput[] }
  >(PROCESS_DATASETS);

  const [_submitEUDRRiskAnalysis] = useMutation<unknown, { input: ISubmitEUDRRiskAnalysisInput }>(
    SUBMIT_EUDR_RISK_ANALYSIS
  );

  const startDatasetProcessing = (inputs: IProcessDatasetInput[]) =>
    _processDatasets({
      variables: {
        inputs,
      },
      refetchQueries: ['GetStatement', 'GetDataset', 'GetDatasetSites'],
      // I believe this is getting updated automatically
      // update: (cache, { data }) => {
      //   if (data?.processEUDRDatasets.eudrDatasets.length) {
      //     data?.processEUDRDatasets.eudrDatasets.forEach(dataset => {
      //       cache.writeFragment({
      //         id: dataset.id,
      //         fragment: DATASET_TABLE_REPRESENTATION_FRAGMENT,
      //         fragmentName: 'eudrDatasetTableRepresentationValues',
      //         data: {
      //           dataset,
      //         },
      //       });
      //     });
      //   }
      // },
    });

  const createDatasets = async (
    values: IGeoUploadFormValues,
    eudrStatementId?: string
  ): Promise<
    FetchResult<{
      createDataset: {
        dataset: IDataset;
      };
    }>[]
  > => {
    if (!values.length) {
      return Promise.resolve([]);
    }
    try {
      return await Promise.all(
        values.map(
          async value =>
            await _createDataset({
              variables: {
                input: {
                  title: value.title,
                  originCountry: value.countryCode,
                  rawMaterialId: value.rawMaterialId,
                  ownedById: value.ownedBy.id,
                  documentIds: value.files.map(({ id }) => id),
                  eudrStatementIds: eudrStatementId ? [eudrStatementId] : undefined,
                },
              },
            })
        )
      );
    } catch (error) {
      console.error('Something went wrong creating datasets', error);
      return [];
    }
  };

  const updateDataset = async (datasetId: string, input: IUpdateDatasetInput) =>
    _updateDataset({ variables: { id: datasetId, input } });

  const updateDatasets = async (
    values: (IGeoUploadFormValue & { datasetId: string })[] // datasetid is required
  ): Promise<
    FetchResult<{
      updateDataset: {
        dataset: IDataset;
      };
    }>[]
  > => {
    if (!values.length) {
      return Promise.resolve([]);
    }
    try {
      return await Promise.all(
        values.map(
          async value =>
            await _updateDataset({
              variables: {
                id: value.datasetId,
                input: {
                  title: value.title,
                  originCountry: value.countryCode,
                  rawMaterialId: value.rawMaterialId,
                  ownedById: value.ownedBy.id,
                  documentIds: value.files.map(({ id }) => id),
                },
              },
            })
        )
      );
    } catch (error) {
      console.error('Something went wrong creating datasets', error);
      return [];
    }
  };

  const deleteDataset = async (dataset: { id: string; documents?: { id: string }[] }) => {
    try {
      // TODO: move the document deletion to the backend
      if (dataset.documents?.length) {
        deleteDocument({ variables: { ids: dataset.documents.map(({ id }) => id) } });
      }
      _deleteDataset({ variables: { id: dataset.id } });
    } catch (error) {
      console.error('Something went wrong deleting datasets', error);
      return [];
    }
  };

  const submitEudrRiskAnalysis = async (datasetBatches: ISubmitEUDRRiskAnalysisInput) =>
    _submitEUDRRiskAnalysis({
      variables: {
        input: datasetBatches,
      },
    });

  return {
    updateDataset,
    createDatasets,
    deleteDataset,
    startDatasetProcessing,
    updateDatasets,
    submitEudrRiskAnalysis,
  };
};

export default useDatasetMutation;
