import React, { FC, ReactElement } from 'react';
import { DatasetError } from 'types/dataset.types';
import { Box, styled } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface IWarningTooltipProps {
  warnings: DatasetError[];
  children: ReactElement;
}

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    maxWidth: 480,
    padding: theme.spacing(3),
    boxShadow: theme.custom.shadows[1],
  },
}));

const Title = styled(ThemeTypography)({
  textTransform: 'uppercase',
});

const WarningTextContainer = styled(Box)(({ theme }) => ({
  borderRadius: 4,
  padding: '2px 4px',
  background: theme.custom.themeColors.accent[20],

  '&:not(:last-child)': {
    marginBottom: 10,
  },
}));

const NumberCyrcle = styled(Box)(({ theme }) => ({
  width: 16,
  height: 16,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.custom.themeColors.accent[60],
}));

const WarningTooltip: FC<IWarningTooltipProps> = ({ warnings, children }) => {
  return (
    <StyledTooltip
      title={
        <Box>
          <Box display="flex" alignItems="center" gap={1} mb={1}>
            <NumberCyrcle>
              <ThemeTypography variant="BODY_SMALL">{warnings.length}</ThemeTypography>
            </NumberCyrcle>
            <Title variant="TITLE_EXTRA_SMALL">Plot warnings</Title>
          </Box>

          {warnings.map((warning, index) => (
            <WarningTextContainer key={index}>
              <ThemeTypography variant="BODY_SMALL" color="GRAY_100">
                {warning.errorMessage}
              </ThemeTypography>
            </WarningTextContainer>
          ))}
        </Box>
      }
    >
      {children}
    </StyledTooltip>
  );
};

export default WarningTooltip;
