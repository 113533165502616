import { Box, styled, Tooltip } from '@mui/material';
import { CardContainer, ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC, useMemo, MouseEvent } from 'react';
import Moment from 'react-moment';
import { DATASET_STATUS, IDataset } from 'types/dataset.types';
import { getCountryLabelFromCode } from 'utils/country.utils';
import { StyledTooltip } from 'components/DataImport/WarningTooltip';
import { FlexBox } from 'components/Structure';

interface IDatasetItemProps {
  dataset: IDataset;
  disabled?: boolean;
  onClick?: (id: string) => void;
  onClickViewPlots?: (id: string) => void;
}

const ErrorBadge = styled(Box)(({ theme }) => ({
  backgroundColor: theme.custom.themeColors.error[60],
  padding: '2px 8px',
  borderRadius: 16,
}));

const ErrorMessage = styled(Box)(({ theme }) => ({
  backgroundColor: theme.custom.themeColors.error[20],
  padding: '2px 4px',
  borderRadius: 4,
}));

const ErrorCount = styled(Box)(({ theme }) => ({
  background: theme.custom.themeColors.error[60],
  borderRadius: 10,
  width: 20,
  height: 20,
  flexShrink: 0,
}));

const StatusBadge = styled(Box)(({ theme }) => ({
  backgroundColor: theme.custom.themeColors.grayScale[20],
  padding: '2px 8px',
  borderRadius: 16,
}));

const DatasetItem: FC<IDatasetItemProps> = ({ dataset, disabled, onClick, onClickViewPlots }) => {
  const warningTypes = useMemo(() => {
    const issueCounts: { errorMessage: string; count: number }[] = [];
    dataset.issues?.forEach(error => {
      const existingIssues = issueCounts.find(e => e.errorMessage === error.errorMessage);
      if (existingIssues) {
        existingIssues.count += 1;
      } else {
        issueCounts.push({ errorMessage: error.errorMessage, count: 1 });
      }
    });
    return issueCounts;
  }, [dataset.issues]);

  const handleOnClickViewPlots = (event: MouseEvent) => {
    event.stopPropagation();
    if (!!onClickViewPlots) {
      onClickViewPlots?.(dataset.id);
    }
  };

  return (
    <CardContainer
      padding={1.5}
      width="100%"
      maxWidth="100%"
      blueHover={!!onClick && !disabled}
      disabled={disabled}
      onClick={() => (!disabled ? onClick?.(dataset.id) : undefined)}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Box display="flex" bgcolor="white" borderRadius="6px" padding="4px">
            <Icon name="polygon-data" color="gray-100" size="xxx-large" />
          </Box>
          <Box ml={1.5}>
            <Box display="flex" alignItems="center" gap={1}>
              <ThemeTypography variant="ITEM_TITLE">{dataset.title}</ThemeTypography>
              {dataset.issues.length > 0 && (
                <StyledTooltip
                  title={
                    <Box display="flex" flexDirection="column" gap={1}>
                      {warningTypes
                        .sort((a, b) => b.count - a.count)
                        .map(({ errorMessage, count }, index) => (
                          <Box key={`${index}${count}`} display="flex" alignItems="center" gap={1}>
                            <ErrorCount display="flex" alignItems="center" justifyContent="center">
                              <ThemeTypography variant="BODY_SMALL" color="RED_50">
                                {count}
                              </ThemeTypography>
                            </ErrorCount>
                            <ErrorMessage>
                              <ThemeTypography variant="BODY_SMALL" color="RED_50">
                                {errorMessage}
                              </ThemeTypography>
                            </ErrorMessage>
                          </Box>
                        ))}
                    </Box>
                  }
                >
                  <ErrorBadge>
                    <ThemeTypography variant="BODY_SMALL">
                      {dataset.issues.length} plot errors
                    </ThemeTypography>
                  </ErrorBadge>
                </StyledTooltip>
              )}
              <StatusBadge>
                <ThemeTypography variant="LABEL_INPUT">
                  {DATASET_STATUS[dataset.status]}
                </ThemeTypography>
              </StatusBadge>
            </Box>
            <FlexBox gap={1}>
              <FlexBox gap={0.5}>
                <Icon name="tag-filled" size="small" color="gray-60" />
                <ThemeTypography variant="LABEL_INPUT">Geo-Dataset</ThemeTypography>
              </FlexBox>
              <FlexBox gap={0.5}>
                <Icon name="raw-material" size="small" color="gray-60" />
                <ThemeTypography autoOverflow variant="LABEL_INPUT">
                  {dataset.rawMaterial?.title}
                </ThemeTypography>
              </FlexBox>
              <FlexBox gap={0.5}>
                <Icon name="location" size="small" color="gray-60" />
                <ThemeTypography autoOverflow variant="LABEL_INPUT">
                  {getCountryLabelFromCode(dataset.originCountry)}
                </ThemeTypography>
              </FlexBox>
              <FlexBox gap={0.5}>
                <Icon name="partner" size="small" color="gray-60" />
                <ThemeTypography autoOverflow variant="LABEL_INPUT">
                  {dataset.ownedBy.name}
                </ThemeTypography>
              </FlexBox>
              <FlexBox gap={0.5}>
                <Icon name="calendar" size="small" color="gray-60" />
                <ThemeTypography autoOverflow variant="LABEL_INPUT">
                  Imported <Moment format="DD/MM/YYYY">{dataset.createdTimestamp}</Moment>
                </ThemeTypography>
              </FlexBox>
            </FlexBox>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" minWidth="fit-content" mr={1}>
          {!!onClickViewPlots && (
            <Tooltip title="View all plots of that dataset">
              <ThemeButton
                color="BLUE_ICE"
                startIcon={<Icon name="location" size="small" />}
                onClick={event => handleOnClickViewPlots(event as MouseEvent)}
              >
                View plots
              </ThemeButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </CardContainer>
  );
};

export default DatasetItem;
