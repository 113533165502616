import {
  ActionContainer,
  EmptySearchResults,
  ErrorState,
  InfiniteScrollWrapper,
  PageContainer,
  PageTitle,
} from 'components/Structure';
import React, { FC, useMemo } from 'react';
import { Loader } from 'components/Forms';
import SitesTable from 'components/Sites/SitesTable/SitesTable';
import { SitesTableColumnKeys } from 'components/Sites/SitesTable/sitesHeaderColumns';
import PartnerSkeleton from 'components/Partners/Skeleton/PartnerSkeleton';
import { usePageTitle } from 'components/hooks';
import { Box, IconButton, InputAdornment, styled } from '@mui/material';
import { SearchInput } from 'components/Forms/SearchToolbar';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Search } from '@styled-icons/bootstrap/Search';
import { Close } from '@styled-icons/evaicons-solid/Close';
import useSites from 'hooks/useSites';
import useSite from 'hooks/useSite';
import { IBaseFarmSite, SiteType, SiteViewOption } from 'types/site.types';
import { useNavigate } from 'react-router-dom';

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: 0,
  margin: 0,
}));

const ClusterSites: FC = () => {
  usePageTitle('Cluster sites');
  const navigate = useNavigate();
  const { site, loading: siteLoading, error: siteError } = useSite();
  const {
    sites,
    loading,
    error,
    hasNextPage,
    searchTerm,
    handlePageEndReached,
    handleEditSite,
    handleDeleteSite,
    handleCreateSite,
    handleSearchTermChange,
    handleEditSiteCultivatedArea,
  } = useSites({ siteViewOption: SiteViewOption.LIST, siteClusterId: site?.id });

  const siteType = useMemo(() => {
    if (!sites?.length) return undefined;

    return sites[0].siteType;
  }, [sites]);

  const rawMaterial = useMemo(() => {
    if (!sites?.length) return undefined;

    return siteType === SiteType.FARM ? (sites[0] as IBaseFarmSite).rawMaterial : undefined;
  }, [sites, siteType]);

  const handleSiteClick = (siteId: string) => {
    navigate(`/sites/external/${siteId}`);
  };

  if (error || siteError) return <ErrorState />;
  if (siteLoading) return <Loader />;

  return (
    <PageContainer>
      <PageTitle
        autoBackNavigation
        title="Cluster sites"
        goBackLabel="All sites"
        goBackUrl="/sites/external"
        titleExtension={site?.title}
      />
      <Box mb={3} />
      <ActionContainer>
        <SearchInput
          setDebouncedState={handleSearchTermChange}
          className="search-input"
          data-cy="site-search-input"
          delay={500}
          placeholder="Search"
          initialValue={searchTerm}
          inputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {searchTerm === '' ? (
                  <Search size={12} />
                ) : (
                  <CloseButton onClick={() => handleSearchTermChange('')}>
                    <Close size={16} />
                  </CloseButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        <Box ml={1} />
        <ThemeButton
          size="medium"
          color="YELLOW"
          onClick={() =>
            handleCreateSite({
              siteClusterId: site?.id,
              defaultSiteType: siteType,
              defaultRawMaterialId: rawMaterial?.id,
              defaultCountry: site?.locationName,
            })
          }
          startIcon={<Icon name="plus" />}
          data-cy="create-site-btn"
        >
          Add site
        </ThemeButton>
      </ActionContainer>
      {loading && <Loader />}
      {sites.length > 0 && !loading && (
        <InfiniteScrollWrapper hasMore={hasNextPage} next={handlePageEndReached}>
          <SitesTable
            sites={sites}
            columns={[
              SitesTableColumnKeys.TITLE,
              SitesTableColumnKeys.OWNER,
              SitesTableColumnKeys.MODIFIED_TIMESTAMP,
              SitesTableColumnKeys.ACTIONS,
            ]}
            onSiteEdit={handleEditSite}
            onSiteDelete={handleDeleteSite}
            onEditCultivatedArea={handleEditSiteCultivatedArea}
            onSiteClick={site => handleSiteClick(site.id)}
          />
        </InfiniteScrollWrapper>
      )}

      {!sites.length && !loading && (
        <EmptySearchResults
          message="No search results found"
          action={{
            label: 'Add site',
            onClick: () =>
              handleCreateSite({
                siteClusterId: site?.id,
                defaultSiteType: siteType,
                defaultRawMaterialId: rawMaterial?.id,
                defaultCountry: site?.locationName,
              }),
          }}
          skeletonComponent={<PartnerSkeleton title={searchTerm ? searchTerm : ''} />}
        />
      )}
    </PageContainer>
  );
};

export default ClusterSites;
