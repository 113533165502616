import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loader } from 'components/Forms';
import React, { FC, PropsWithChildren } from 'react';
import { InView } from 'react-intersection-observer';
import ScrollableTableContainer from './ScrollableTableContainer';

const LoadingContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  height: 60,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
interface IInfiniteScrollWrapper extends PropsWithChildren {
  hasMore: boolean;
  scrollThreshold?: number;
  height?: number;
  next: () => void;
}

const InfiniteScrollWrapper: FC<IInfiniteScrollWrapper> = ({
  children,
  hasMore,
  scrollThreshold = 0.8,
  height,
  next,
}) => {
  const handleNext = (inView: boolean) => {
    if (inView && hasMore) {
      next();
    }
  };

  return (
    <>
      <ScrollableTableContainer height={height}>{children}</ScrollableTableContainer>
      {hasMore && (
        <InView
          trackVisibility
          as="div"
          delay={200}
          threshold={scrollThreshold}
          initialInView
          onChange={handleNext}
        >
          <LoadingContainer>
            <Loader size={20} />
          </LoadingContainer>
        </InView>
      )}
    </>
  );
};

export default InfiniteScrollWrapper;
