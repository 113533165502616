import React, { FC, useEffect, useMemo, useState } from 'react';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { useQuery } from '@apollo/client';
import { GET_DATASETS } from 'graphql/queries/dataset.queries';
import { Loader } from 'components/Forms';
import { ErrorState, InfiniteScrollWrapper } from 'components/Structure';
import { DatasetStatus, IDataset } from 'types/dataset.types';
import { GraphQlConnection } from 'types/types';
import { Box, Checkbox, IconButton, InputAdornment, styled } from '@mui/material';
import { SearchInput } from 'components/Forms/SearchToolbar';
import { Search } from '@styled-icons/bootstrap';
import { Close } from '@styled-icons/ionicons-sharp';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import DatasetItem from '../../DatasetItem';
import { ThemeTypography } from 'designSystem';
import { useDialog } from 'components/hooks';
import { removeGraphConnections } from 'utils/graphql.utils';

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: 0,
  margin: 0,
}));

const SelectedCount = styled(Box)(({ theme }) => ({
  background: theme.custom.themeColors.grayScale[20],
  borderRadius: 4,
  padding: '2px 8px',
}));

const SelectGeoData: FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedDatasets, setSelectedDatasets] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { selectedStatementId, setSelectedSubSection, updateStatement, statement } =
    useEudrComplianceGuide();
  const { openDialog } = useDialog();

  const {
    data,
    loading: loadingDatasets,
    error,
    fetchMore,
  } = useQuery<{ datasets: GraphQlConnection<IDataset> }>(GET_DATASETS, {
    variables: {
      filters: {
        searchTerm: searchTerm,
        status: [DatasetStatus.COMPLETED],
      },
      first: 15,
    },
  });

  const hasNextPage: boolean = !!data?.datasets?.pageInfo?.hasNextPage;
  const endCursor: string | undefined = data?.datasets?.pageInfo?.endCursor;

  const datasets = useMemo(
    () => (data?.datasets ? removeGraphConnections(data?.datasets) : []),
    [data]
  );

  useEffect(() => {
    if (statement) {
      setSelectedDatasets(statement.datasets.map(dataset => dataset.id));
    }
  }, [statement]);

  const handlePageEndReached = () => {
    if (endCursor) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      });
    }
  };

  const handleSelect = (id: string) => {
    const isSelected = selectedDatasets.includes(id);
    if (!isSelected) {
      setSelectedDatasets([...selectedDatasets, id]);
    } else {
      setSelectedDatasets(selectedDatasets.filter(datasetId => datasetId !== id));
    }
  };

  const handleToggleAll = () => {
    if (selectedDatasets.length === datasets.length) {
      setSelectedDatasets([]);
    } else {
      setSelectedDatasets(
        datasets
          .filter(dataset => dataset.status === DatasetStatus.COMPLETED)
          .map(dataset => dataset.id) || []
      );
    }
  };

  const handleClickNext = async () => {
    setLoading(true);
    await updateStatement(selectedStatementId, {
      datasetsIds: selectedDatasets,
    });
    setLoading(false);
  };

  if (error) {
    return <ErrorState />;
  }

  return (
    <EudrComplianceGuideSubSection
      allowNextStepNavigation={!!selectedDatasets.length}
      nextStepLoading={loading}
      onPreviousStepClick={() => setSelectedSubSection('affected-origins')}
      onNextStepClick={handleClickNext}
    >
      <Box pt={2} mt={-2}>
        <Box display="flex" alignItems="center" gap={1}>
          <Checkbox
            checked={selectedDatasets.length === data?.datasets.edges.length}
            onClick={handleToggleAll}
          />
          <SearchInput
            setDebouncedState={setSearchTerm}
            className="search-input"
            data-cy="dataset-search-input"
            delay={500}
            placeholder="Search"
            initialValue={searchTerm}
            inputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {searchTerm === '' ? (
                    <Search size={12} />
                  ) : (
                    <CloseButton onClick={() => setSearchTerm('')}>
                      <Close size={16} />
                    </CloseButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          {selectedDatasets.length > 0 && (
            <SelectedCount>
              <ThemeTypography variant="LABEL_INPUT">
                {selectedDatasets.length} selected
              </ThemeTypography>
            </SelectedCount>
          )}
        </Box>
        {loadingDatasets ? (
          <Box position="relative" height="100%" p={5}>
            <Loader />
          </Box>
        ) : (
          <InfiniteScrollWrapper hasMore={hasNextPage} next={handlePageEndReached}>
            <Box display="flex" width="100%" flexDirection="column" gap={1} pt={2}>
              {datasets.map(dataset => {
                const isSelected = selectedDatasets.includes(dataset.id);
                const disabled = dataset.status !== DatasetStatus.COMPLETED;
                return (
                  <Box display="flex" alignItems="center" key={dataset.id}>
                    <Box mr={1}>
                      <Checkbox
                        checked={isSelected}
                        onClick={() => handleSelect(dataset.id)}
                        disabled={disabled}
                      />
                    </Box>
                    <DatasetItem
                      dataset={dataset}
                      disabled={disabled}
                      onClick={handleSelect}
                      onClickViewPlots={() =>
                        openDialog({ type: 'DATASET_PLOT_OVERVIEW', props: { dataset: dataset } })
                      }
                    />
                  </Box>
                );
              })}
            </Box>
          </InfiniteScrollWrapper>
        )}
      </Box>
    </EudrComplianceGuideSubSection>
  );
};

export default SelectGeoData;
