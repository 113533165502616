import { gql } from '@apollo/client';
import { IMAGE_VARIANT_FRAGMENT } from './media.fragments';
import { RAW_MATERIAL_FRAGMENT } from './component.fragments';
import { BASE_PARTNER_COMPANY_FRAGMENT } from './partner.fragments';

export const BASE_SITE_FRAGMENT = (siteType: string) => gql`
  fragment base${siteType}Values on ${siteType} {
    id
    ownedBy {
      ...basePartnerCompanyValues
    }
    createdBy {
      ...basePartnerCompanyValues
    }
    image {
      ...imageVariant
    }
    siteType
    title
    locationCoordinates {
      lat
      lng
    }
    modifiedTimestamp
    locationName
    imageId
    externalId
    description
    coordinateFeatures {
      featureType
      locationName
      center {
        lat
        lng
      }
    }
    inUse
    countryCode
  }
    ${IMAGE_VARIANT_FRAGMENT}
    ${BASE_PARTNER_COMPANY_FRAGMENT}
  `;

export const BASE_CULTIVATED_AREAS_FRAGMENT = gql`
  fragment baseCultivatedAreaValues on CultivatedAreas {
    id
    farmId
    coordinates
    areaType
    centerPoint {
      lat
      lng
    }
  }
`;

/**
 * Base fragment does not contain the cultivatedAreas since it is a recursive fragment
 */
export const BASE_FARM_SITE_FRAGMENT = gql`
  fragment baseFarmSiteValues on Farm {
    ...baseFarmValues
    size
    rawMaterial {
      ...rawMaterialValues
    }
  }
  ${BASE_SITE_FRAGMENT('Farm')}
  ${RAW_MATERIAL_FRAGMENT}
`;

/**
 * Contains the base fragment of the farm site which excludes the cultivatedAreas
 */
export const CULTIVATED_AREAS_FRAGMENT = gql`
  fragment cultivatedAreaValues on CultivatedAreas {
    ...baseCultivatedAreaValues
    farm {
      ...baseFarmSiteValues
    }
  }
  ${BASE_CULTIVATED_AREAS_FRAGMENT}
  ${BASE_FARM_SITE_FRAGMENT}
`;

export const FARM_SITE_FRAGMENT = gql`
  fragment farmSiteValues on Farm {
    ...baseFarmSiteValues
    numberOfFarmers
    landOwnership
    cultivatedAreas {
      ...baseCultivatedAreaValues
    }
    additionalRawMaterialIds
  }
  ${BASE_FARM_SITE_FRAGMENT}
  ${BASE_CULTIVATED_AREAS_FRAGMENT}
`;

export const GENERIC_SITE_FRAGMENT = gql`
  fragment genericSiteValues on GenericSite {
    ...baseGenericSiteValues
  }
  ${BASE_SITE_FRAGMENT('GenericSite')}
`;

export const MINE_SITE_FRAGMENT = gql`
  fragment mineValues on Mine {
    ...baseMineValues
  }
  ${BASE_SITE_FRAGMENT('Mine')}
`;

export const FOREST_SITE_FRAGMENT = gql`
  fragment forestValues on Forest {
    ...baseForestValues
  }
  ${BASE_SITE_FRAGMENT('Forest')}
`;

export const COLLECTION_SITE_FRAGMENT = gql`
  fragment collectionSiteValues on CollectionSite {
    ...baseCollectionSiteValues
  }
  ${IMAGE_VARIANT_FRAGMENT}
  ${BASE_SITE_FRAGMENT('CollectionSite')}
`;

export const ORIGIN_SITE_FRAGMENT = gql`
  fragment originSiteValues on OriginSite {
    ...baseOriginSiteValues
  }
  ${IMAGE_VARIANT_FRAGMENT}
  ${BASE_SITE_FRAGMENT('OriginSite')}
`;

export const PLANT_SITE_FRAGMENT = gql`
  fragment plantValues on Plant {
    ...basePlantValues
  }
  ${BASE_SITE_FRAGMENT('Plant')}
`;

export const PROCESSING_SITE_FRAGMENT = gql`
  fragment processingSiteValues on ProcessingSite {
    ...baseProcessingSiteValues
  }
  ${BASE_SITE_FRAGMENT('ProcessingSite')}
`;

export const FACTORY_SITE_FRAGMENT = gql`
  fragment factoryValues on Factory {
    ...baseFactoryValues
  }
  ${BASE_SITE_FRAGMENT('Factory')}
`;

export const MANUFACTURING_SITE_FRAGMENT = gql`
  fragment manufacturingSiteValues on ManufacturingSite {
    ...baseManufacturingSiteValues
  }
  ${BASE_SITE_FRAGMENT('ManufacturingSite')}
`;

export const WAREHOUSE_SITE_FRAGMENT = gql`
  fragment warehouseValues on Warehouse {
    ...baseWarehouseValues
  }
  ${BASE_SITE_FRAGMENT('Warehouse')}
`;

export const HANDLING_SITE_FRAGMENT = gql`
  fragment handlingSiteValues on HandlingSite {
    ...baseHandlingSiteValues
  }
  ${BASE_SITE_FRAGMENT('HandlingSite')}
`;

export const GROCERY_STORE_SITE_FRAGMENT = gql`
  fragment groceryStoreValues on GroceryStore {
    ...baseGroceryStoreValues
  }
  ${BASE_SITE_FRAGMENT('GroceryStore')}
`;

export const DISTRIBUTION_SITE_FRAGMENT = gql`
  fragment distributionSiteValues on DistributionSite {
    ...baseDistributionSiteValues
  }
  ${BASE_SITE_FRAGMENT('DistributionSite')}
`;

export const SITE_CLUSTER_FRAGMENT = gql`
  fragment siteClusterValues on SiteCluster {
    ...baseSiteClusterValues
    sitesType
    sites(first: 3) {
      count
      summary {
        workersAmount
        additionalRawMaterialTitles
      }
      edges {
        node {
          ... on GenericSite {
            ...genericSiteValues
          }
          ... on Farm {
            ...farmSiteValues
          }
          ... on Mine {
            ...mineValues
          }
          ... on Forest {
            ...forestValues
          }
          ... on OriginSite {
            ...originSiteValues
          }
          ... on CollectionSite {
            ...collectionSiteValues
          }
          ... on Plant {
            ...plantValues
          }
          ... on ProcessingSite {
            ...processingSiteValues
          }
          ... on Factory {
            ...factoryValues
          }
          ... on ManufacturingSite {
            ...manufacturingSiteValues
          }
          ... on Warehouse {
            ...warehouseValues
          }
          ... on HandlingSite {
            ...handlingSiteValues
          }
          ... on GroceryStore {
            ...groceryStoreValues
          }
          ... on DistributionSite {
            ...distributionSiteValues
          }
        }
      }
    }
  }
  ${IMAGE_VARIANT_FRAGMENT}
  ${GENERIC_SITE_FRAGMENT}
  ${FARM_SITE_FRAGMENT}
  ${MINE_SITE_FRAGMENT}
  ${FOREST_SITE_FRAGMENT}
  ${COLLECTION_SITE_FRAGMENT}
  ${ORIGIN_SITE_FRAGMENT}
  ${PLANT_SITE_FRAGMENT}
  ${PROCESSING_SITE_FRAGMENT}
  ${FACTORY_SITE_FRAGMENT}
  ${MANUFACTURING_SITE_FRAGMENT}
  ${WAREHOUSE_SITE_FRAGMENT}
  ${HANDLING_SITE_FRAGMENT}
  ${GROCERY_STORE_SITE_FRAGMENT}
  ${DISTRIBUTION_SITE_FRAGMENT}
  ${BASE_SITE_FRAGMENT('SiteCluster')}
`;

/** #### SITE_FRAGMENT ####
... on GenericSite {
  ...genericSiteValues
}
... on Farm {
  ...farmValues
}
... on Mine {
  ...mineValues
}
... on Forest {
  ...forestValues
}
... on CollectionSite {
  ...collectionSiteValues
}
... on OriginSite {
  ...originSiteValues
}
... on Plant {
  ...plantValues
}
... on ProcessingSite {
  ...processingSiteValues
}
... on Factory {
  ...factoryValues
}
... on ManufacturingSite {
  ...manufacturingSiteValues
}
... on Warehouse {
  ...warehouseValues
}
... on HandlingSite {
  ...handlingSiteValues
}
... on GroceryStore {
  ...groceryStoreValues
}
... on DistributionSite {
  ...distributionSiteValues
}
... on SiteCluster {
  ...siteClusterValues
}
__typename  
${GENERIC_SITE_FRAGMENT}
${FARM_SITE_FRAGMENT}
${MINE_SITE_FRAGMENT}
${FOREST_SITE_FRAGMENT}
${COLLECTION_SITE_FRAGMENT}
${ORIGIN_SITE_FRAGMENT}
${PLANT_SITE_FRAGMENT}
${PROCESSING_SITE_FRAGMENT}
${FACTORY_SITE_FRAGMENT}
${MANUFACTURING_SITE_FRAGMENT}
${WAREHOUSE_SITE_FRAGMENT}
${HANDLING_SITE_FRAGMENT}
${GROCERY_STORE_SITE_FRAGMENT}
${DISTRIBUTION_SITE_FRAGMENT}
${SITE_CLUSTER_FRAGMENT}
 */
