import React, { FC } from 'react';
import { Box, IconButton, styled, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { CardContainer, ThemeTypography } from 'designSystem';
import Image from 'designSystem/DataDisplay/Image/Image';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { SiteLabelKeys } from 'utils/site.utils';
import { ISite } from 'types/site.types';
import { DatasetError } from 'types/dataset.types';
import WarningTooltip from './WarningTooltip';
import { FlexBox } from 'components/Structure';
import useMeasure from 'react-use/lib/useMeasure';

interface ISiteItemProps {
  site: ISite;
  warnings: DatasetError[];
  onEdit: (site: ISite) => void;
  onEditCultivatedArea: (site: ISite) => void;
  onDelete: (site: ISite) => void;
}

const DatasetItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '&:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
}));

const NumberCircle = styled(Box)(({ theme }) => ({
  width: 16,
  height: 16,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.custom.themeColors.accent[60],
  flexShrink: 0,
}));

const WarningTextContainer = styled(Box)<{ cursor?: string }>(({ theme, cursor }) => ({
  borderRadius: 4,
  padding: '4px 4px',
  background: theme.custom.themeColors.accent[20],
  cursor,
}));

const TitleContainer = styled(FlexBox)(({ theme }) => ({
  wordBreak: 'break-all',
}));

const SiteItem: FC<ISiteItemProps> = ({
  site,
  warnings,
  onEdit,
  onEditCultivatedArea,
  onDelete,
}) => {
  const theme = useTheme();
  const isMediumScreenAndSmaller = useMediaQuery(theme.breakpoints.down('lg'));
  const [containerRef, { width: containerWidth }] = useMeasure<HTMLDivElement>();
  const warningTextMaxWidth = containerWidth - (isMediumScreenAndSmaller ? 550 : 700);

  const hasWarnings = warnings.length > 0;

  return (
    <DatasetItem ref={containerRef} key={site.id} display="flex" alignItems="center" gap={1}>
      <Icon
        name={hasWarnings ? 'error-filled' : 'check-filled'}
        size="large"
        color={hasWarnings ? 'yellow' : 'green'}
      />
      <CardContainer padding={1.5}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            {!!site.image ? (
              <Image width={44} height={44} image={site.image} />
            ) : (
              <Box display="flex" bgcolor="white" borderRadius="6px" padding="4px">
                <Icon name="site-placeholder" size="xx-large" color="blue-ice" />
              </Box>
            )}
            <Box ml={1.5}>
              <Box display="flex" alignItems="center" gap={0.5}>
                <TitleContainer gap={1}>
                  <ThemeTypography
                    variant="ITEM_TITLE"
                    autoOverflow
                    maxWidth={isMediumScreenAndSmaller ? 200 : 300}
                  >
                    {site.title}
                  </ThemeTypography>
                  {site.externalId && (
                    <>
                      <ThemeTypography variant="BODY_MEDIUM" color="GRAY_100">
                        |
                      </ThemeTypography>
                      <ThemeTypography
                        autoOverflow
                        maxWidth={isMediumScreenAndSmaller ? 100 : 150}
                        variant="BODY_MEDIUM"
                        color="GRAY_100"
                      >
                        {site.externalId}
                      </ThemeTypography>
                    </>
                  )}
                </TitleContainer>

                <Box ml={0.5} />
                {hasWarnings && (
                  <>
                    <NumberCircle>
                      <ThemeTypography variant="BODY_SMALL_BOLD" color="GRAY_100">
                        {warnings.length}
                      </ThemeTypography>
                    </NumberCircle>
                    <WarningTooltip warnings={warnings}>
                      <WarningTextContainer cursor="default">
                        <ThemeTypography
                          variant="BODY_SMALL"
                          color="GRAY_100"
                          autoOverflow
                          maxWidth={warningTextMaxWidth}
                          hideTooltip
                        >
                          {warnings[0].errorMessage}
                        </ThemeTypography>
                      </WarningTextContainer>
                    </WarningTooltip>
                    {warnings.length > 1 && (
                      <WarningTooltip warnings={warnings}>
                        <WarningTextContainer cursor="default">
                          <ThemeTypography variant="BODY_SMALL" color="GRAY_100">
                            ...
                          </ThemeTypography>
                        </WarningTextContainer>
                      </WarningTooltip>
                    )}
                  </>
                )}
              </Box>
              <Box display="flex" alignItems="center">
                <Icon name="tag-filled" size="small" color="gray-60" />
                <Box ml={0.5} />
                <ThemeTypography variant="LABEL_INPUT">
                  {SiteLabelKeys[site.siteType]}
                </ThemeTypography>
                <Box ml={1} />
                <Icon name="location" size="small" color="gray-60" />
                <Box ml={0.5} />
                <ThemeTypography autoOverflow variant="LABEL_INPUT">
                  {site.locationName}
                </ThemeTypography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" gap={0.5} mr={1}>
            <Tooltip title="Edit cultivation area">
              <IconButton onClick={() => onEditCultivatedArea(site)}>
                <Icon name="polygon-mapping" size="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit site">
              <IconButton onClick={() => onEdit(site)}>
                <Icon name="edit" size="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete site">
              <IconButton onClick={() => onDelete(site)}>
                <Icon name="delete" size="medium" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </CardContainer>
    </DatasetItem>
  );
};

export default SiteItem;
