import { useQuery } from '@apollo/client';
import { GET_DATASET_SITES } from 'graphql/queries/dataset.queries';
import React, { FC, useMemo } from 'react';
import { IDataset } from 'types/dataset.types';
import { DialogDefault } from 'designSystem';
import { IDefaultDialogProps } from 'types/dialog.types';
import DatasetItem from '../DatasetItem';
import { Box, DialogContent, styled } from '@mui/material';
import { Loader } from 'components/Forms';
import { ErrorState } from 'components/Structure';
import CultivationFarmArea from 'components/CultivatedFarmArea/CultivationFarmArea';
import { InlineHelperText } from 'components/Product/InlineHelperText/InlineHelperText';
import { GraphQlConnection } from 'types/types';
import { IFarmSite, ISite, SiteType } from 'types/site.types';
import { removeGraphConnections } from 'utils/graphql.utils';

interface IDatasetPlotOverviewDialogProps extends IDefaultDialogProps {
  dataset: IDataset;
}

const StyledDialog = styled(DialogDefault)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    paddingBottom: '0 !important',
  },
}));

const DatasetPlotOverviewDialog: FC<IDatasetPlotOverviewDialogProps> = ({
  dataset,
  open,
  onClose,
}) => {
  const { data, loading, error } = useQuery<{
    dataset: { sites: GraphQlConnection<ISite> };
  }>(GET_DATASET_SITES, {
    variables: {
      datasetId: dataset.id,
    },
  });

  const farms: IFarmSite[] = useMemo(
    () =>
      data?.dataset.sites
        ? (removeGraphConnections(data.dataset.sites).filter(
            site => site.siteType === SiteType.FARM
          ) as IFarmSite[])
        : [],
    [data]
  );

  return (
    <StyledDialog open={!!open} title="Plot overview" maxWidth="md" fullWidth onClose={onClose}>
      <DialogContent>
        <Box mt={2.5}>
          <DatasetItem dataset={dataset} />
          {error ? (
            <Box p={10}>
              <ErrorState center={false} />
            </Box>
          ) : loading || !data?.dataset ? (
            <Box position="relative" py={15}>
              <Loader />
            </Box>
          ) : (
            <>
              <Box mt={3} />
              <CultivationFarmArea farms={farms || []} warnings={dataset.issues || []} />
              <Box mt={2} mb={1}>
                <InlineHelperText
                  variant="INFO"
                  helperText="To edit geo-data, go back to the geo-data collection step or manage your geo-data per partner in the platform."
                />
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default DatasetPlotOverviewDialog;
