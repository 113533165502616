import { useQuery } from '@apollo/client';
import { ChainMappingEvent } from 'components/ChainMapping/utils/mapping.types';
import {
  getActivitiesInBoundsOfSteps,
  getStepFromXPosition,
  isInBoundsOfSubChain,
} from 'components/ChainMapping/utils/mapping.utils';
import { useDialog, useLogEvent } from 'components/hooks';
import { GET_CHAIN, GET_ONBOARDING_CHAIN } from 'graphql/queries';
import omit from 'lodash/omit';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IChain, IChainResponse, IChainStep, IOnboardingChainResponse } from 'types/chain.types';
import { RouteParamsWithId } from 'types/router.types';
import useComponentChainActions from './useComponentChainActions';
import useComponentChainOnboardingSteps from './useComponentChainOnboardingSteps';

const useComponentChainMapping = () => {
  const { logEvent } = useLogEvent();
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const { id: paramId } = useParams<RouteParamsWithId>() as { id: string };
  const { displayOnboardingTour, onboardingTourStep, handleStartOnboardingTour } =
    useComponentChainOnboardingSteps();

  const {
    loading: mutationLoading,
    removeSubChain,
    importSubChains,
    createSteps,
    deleteStep,
    updateStep,
    createActivity,
    updateActivity,
    deleteActivity,
    reorderSubChains,
    initializeChainMapping,
    createSubChainFromStepActivities,
  } = useComponentChainActions();

  const [expandList, setExpandList] = useState<boolean>(false);

  const {
    data,
    loading: queryLoading,
    error,
  } = useQuery<IChainResponse>(GET_CHAIN, {
    variables: { id: paramId },
    skip: !paramId || !!displayOnboardingTour,
    /**
     * Issue TEC-1881: Fetch policy is set to 'no-cache' to ensure that the component chains are always correctly positioned in the chain.
     * Since the same ChainActivities can be used in multiple chains, with different positions, the cache can cause the chains to be displayed incorrectly.
     */
    fetchPolicy: 'no-cache',
  });

  const {
    data: dataOnboarding,
    loading: onboardingQueryLoading,
    error: errorOnboarding,
  } = useQuery<IOnboardingChainResponse>(GET_ONBOARDING_CHAIN, {
    variables: { id: paramId, onboardingStep: onboardingTourStep },
    skip: !paramId || !displayOnboardingTour,
  });

  const componentChain: IChain | undefined = displayOnboardingTour
    ? dataOnboarding?.onboardingChain
    : data?.chain;

  const handleChainMappingAction = (event: ChainMappingEvent) => {
    if (!componentChain) {
      // eslint-disable-next-line no-console
      console.error('Current component chain is not defined');
      return;
    }
    switch (event.type) {
      case 'ADD_ACTIVITY': {
        const selectedStepId = componentChain.chainSteps.find(({ id }) => id === event.stepId)?.id;
        if (!selectedStepId) {
          // eslint-disable-next-line no-console
          console.error('Step not found');
          break;
        }
        openDialog({
          type: 'ACTIVITY_SELECT_DECISION',
          props: {
            selectedStepId,
            steps: componentChain.chainSteps,
            activities: componentChain.chainStepActivities,
            subChains: componentChain.subChains,
            onActivityAdd: (activityId, activity) => {
              createActivity({
                variables: {
                  input: {
                    activityId: activityId,
                    chainStepId: selectedStepId,
                    incomingLinks: activity?.incomingLinks || [],
                  },
                },
              });
            },
          },
        });
        break;
      }
      case 'EDIT_ACTIVITY':
        const activity = componentChain.chainStepActivities.find(
          activity => activity.id === event.elementId
        );
        if (!activity) {
          // eslint-disable-next-line no-console
          console.error('Activity not found');
          return;
        }
        openDialog({
          type: 'ADD_EDIT_ACTIVITY',
          props: {
            mode: 'edit-chain-activity',
            chainStepActivity: activity,
            steps: componentChain.chainSteps,
            activities: componentChain.chainStepActivities,
            subChains: componentChain.subChains,
            onChainActivityEdit: chainActivity =>
              updateActivity({
                variables: {
                  id: chainActivity.id,
                  input: {
                    chainStepId: chainActivity.chainStepId,
                    incomingLinks: chainActivity.incomingLinks,
                  },
                },
              }),
            onActivityDelete: activityId => deleteActivity({ variables: { id: activityId } }),
          },
        });
        break;
      case 'ADD_STEPS':
        openDialog({
          type: 'ADD_STEPS',
          props: {
            chainType: 'COMPONENT_CHAIN',
            steps: componentChain.chainSteps,
            addStepAtIndex: event.targetIndex,
            onAddSteps: steps =>
              createSteps({
                variables: {
                  input: {
                    chainId: paramId,
                    steps: steps.map(step => omit(step, 'isDeletable')),
                  },
                },
              }),
          },
        });
        break;
      case 'EDIT_STEP': {
        const selectedStep = componentChain.chainSteps.find(({ id }) => id === event.elementId);
        if (!selectedStep) {
          // eslint-disable-next-line no-console
          console.error('Step not found');
          break;
        }
        openDialog({
          type: 'EDIT_STEP',
          props: {
            steps: componentChain.chainSteps,
            editStepId: event.elementId,
            isDeletable: selectedStep.isDeletable,
            hasActivities: !!getActivitiesInBoundsOfSteps(
              event.elementId,
              componentChain.chainStepActivities
            ).length,
            onDeleteStep: (stepId: string) => deleteStep({ variables: { id: stepId } }),
            onEditStep: (step: IChainStep) =>
              updateStep({ variables: { input: { id: step.id, title: step.title } } }),
          },
        });
        break;
      }
      case 'IMPORT':
        openDialog({
          type: 'IMPORT_COMPONENT_CHAINS',
          props: {
            // Exclude the sub chain that were already imported into the chain
            chain: componentChain,
            onImport: (importChainIds: string[]) =>
              importSubChains({
                variables: { id: paramId, importChainIds },
              }),
          },
        });
        break;
      case 'REMOVE_SUB_CHAIN':
        const subChain = componentChain.subChains.find(({ id }) => id === event.elementId);
        openDialog({
          type: 'ALERT',
          props: {
            title: 'Remove component chain',
            itemTitle: subChain?.title,
            text: 'Are you sure you want to remove this component chain from this chain? The component chain will remain in your component library and can be imported again.',
            submitText: 'Remove',
            onCancel: () => undefined,
            onSubmit: () => {
              removeSubChain({
                variables: { id: event.elementId },
              });
            },
          },
        });
        break;
      case 'REORDER':
        const subChainIdsOrdered = event.subChainOrder;
        reorderSubChains({ variables: { id: paramId, subChainIdsOrdered } });
        break;
      case 'EDIT_SUB_CHAIN': {
        const subChain = componentChain.subChains.find(({ id }) => id === event.elementId);
        if (!subChain) {
          // eslint-disable-next-line no-console
          console.error('SubChain not found');
          break;
        }
        navigate(`/component-chains/${subChain.childChainId}/editor`);
        break;
      }
      case 'ADD_SUB_CHAIN_FROM_ACTIVITY': {
        openDialog({
          type: 'ALERT',
          props: {
            title: 'Sub chain creation',
            text: 'Are you sure you want to create a new sub chain up to the selected activity? This will move all the connected activities up to the selected one into a new sub chain and import it into the current chain.',
            submitText: 'Create sub chain',
            cancelText: 'Cancel',
            onCancel: () => undefined,
            onSubmit: () => {
              // Backend expects an array of all included step title and their activity ids
              // Ids of sub chain step activities need to be excluded from the array
              const pointPositionToStepActivityMap = new Map<number, string[]>();
              componentChain.chainStepActivities
                .filter(({ id }) => event.stepActivityIds.includes(id))
                .forEach(({ id, pointPosition }) => {
                  const isSubChainStepActivity = componentChain.subChains.some(
                    ({ boundingBoxPosition }) =>
                      isInBoundsOfSubChain(boundingBoxPosition, pointPosition)
                  );
                  if (pointPositionToStepActivityMap.has(pointPosition.x)) {
                    // Skip adding the activity id if it is a sub chain step activity
                    if (!isSubChainStepActivity) {
                      pointPositionToStepActivityMap.get(pointPosition.x)?.push(id);
                    }
                  } else {
                    // Add empty array if it is a sub chain step activity
                    pointPositionToStepActivityMap.set(
                      pointPosition.x,
                      isSubChainStepActivity ? [] : [id]
                    );
                  }
                });
              const stepsActivities = Array.from(pointPositionToStepActivityMap).map(
                ([xIndex, stepActivityIds]) => ({
                  stepActivityIds,
                  stepTitle:
                    getStepFromXPosition(xIndex, componentChain.chainSteps)?.title || 'Unknown', // In the rare case that the step is not found the title will be 'Unknown' and produce a backend error
                })
              );
              createSubChainFromStepActivities({
                variables: {
                  input: { chainId: paramId, stepsActivities, subChainIds: event.subChainIds },
                },
              });
            },
          },
        });

        break;
      }
      case 'OPEN_SITE_CLUSTER': {
        // TODO: We need a general sites route or add the ownership here
        navigate(`/sites/external/${event.elementId}`);
        break;
      }
    }
  };

  const handleCreateByCopyComponentClick = () => {
    openDialog({ type: 'COPY_CHAIN', props: { chainId: paramId } });
  };

  const handleCreateNewChainClick = () => {
    initializeChainMapping({ variables: { id: paramId } });
  };

  const handleEditComponentChain = () => {
    if (!componentChain) {
      // eslint-disable-next-line no-console
      console.error('Current component chain is not defined');
      return;
    }
    openDialog({
      type: 'EDIT_CHAIN',
      props: { chain: componentChain },
    });
  };

  useEffect(() => {
    if (paramId) {
      logEvent('VISIT_COMPONENT_CHAIN_MAPPING', { chainId: paramId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramId]);

  return {
    queryLoading: queryLoading || onboardingQueryLoading,
    mutationLoading,
    error: error || errorOnboarding,
    componentChain,
    /** if onboarding is active and we are on step 8, we want to expand the list to demonstrate the step by step list */
    expandList: !!(expandList || (displayOnboardingTour && onboardingTourStep === 8)),
    handleCreateByCopyComponentClick,
    handleCreateNewChainClick,
    handleChainMappingAction,
    handleEditComponentChain,
    handleExpandList: setExpandList,
    handleClickOnboardingTour: handleStartOnboardingTour,
  };
};

export default useComponentChainMapping;
