import { Box, Grid, styled } from '@mui/material';
import { Edit } from '@styled-icons/boxicons-solid';
import defaultPartnerImage from 'assets/img/partners/default-partner-image.png';
import { PartnerImage } from 'components/Partners/PartnerRequests/PartnerRequestDialog';
import { FlexBox } from 'components/Structure';
import { ThemeButton, ThemeTypography } from 'designSystem';
import ConditionalRender from 'designSystem/Layout/CondionalRender/ConditionalRender';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { IFeatureProperties } from './CultivationFarmArea';
import round from 'lodash/round';
export interface ICultivationFarmAreaInfoPopupProps {
  /**
   * Shows the partner that own the cultivation areas
   */
  showOwner?: boolean;
  infoItem: IFeatureProperties;
  onEditClick?: () => void;
}

const Container = styled(Box)(({ theme }) => ({
  background: theme.custom.themeColors.white,
  padding: theme.spacing(1),
}));

const Tag = styled(FlexBox)(({ theme }) => ({
  background: theme.custom.colors.lightBorderColor,
  height: 21,
  padding: theme.spacing(1),
}));

const ActivityContainer = styled(FlexBox)<{ 'sub-container': booleanish }>(
  ({ theme, 'sub-container': subContainer }) => ({
    background: theme.custom.themeColors.white,
    borderRadius: 4,

    ...(subContainer === 'true'
      ? {
          border: `1px solid ${theme.custom.colors.lightBorderColor}`,
          padding: theme.spacing(1, 2),
        }
      : {}),
    overflow: 'hidden',
  })
);

const PartnerContainer = styled(FlexBox)(({ theme }) => ({
  background: theme.custom.themeColors.primary[5],
  padding: theme.spacing(1),
  borderRadius: 4,
  cursor: 'pointer',
}));

const NumberCircle = styled(FlexBox)(({ theme }) => ({
  width: 16,
  height: 16,
  borderRadius: '50%',
  justifyContent: 'center',
  background: theme.custom.themeColors.accent[60],
}));

const WarningTag = styled(FlexBox)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  justifyContent: 'center',
  padding: theme.spacing(0.5, 2),
  background: theme.custom.themeColors.accent[60],
}));

const WarningTextContainer = styled(FlexBox)(({ theme }) => ({
  borderRadius: 4,
  padding: theme.spacing(0.5),
  gap: theme.spacing(1),
  background: theme.custom.themeColors.accent[20],
}));

const CultivationFarmAreaInfoPopup: FC<ICultivationFarmAreaInfoPopupProps> = ({
  showOwner,
  infoItem: { title, name, areaSize, outputTitle, ownedBy, warnings },
  onEditClick,
}) => {
  const navigate = useNavigate();

  const handlePartnerClick = () => navigate(`/partners/${ownedBy?.id}`);

  const activityItem = (
    <ActivityContainer sub-container={Booleanish(showOwner)}>
      <Icon name="location" size="x-large" mr={2} color="blue-ice" />
      <Box overflow="hidden">
        <FlexBox overflow="hidden">
          <FlexBox overflow="hidden" gap={1}>
            <ThemeTypography variant="ITEM_TITLE" autoOverflow>
              {title}
            </ThemeTypography>
            {!!warnings?.length && (
              <NumberCircle>
                <ThemeTypography variant="BODY_SMALL_BOLD" color="GRAY_100">
                  {warnings.length}
                </ThemeTypography>
              </NumberCircle>
            )}
          </FlexBox>

          {outputTitle && (
            <>
              <Icon name="arrow-right" size="medium" mx={1} />
              <Tag overflow="hidden">
                <ThemeTypography variant="LABEL_INPUT" autoOverflow>
                  {outputTitle}
                </ThemeTypography>
              </Tag>
            </>
          )}
        </FlexBox>
        <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80" autoOverflow maxWidth={160}>
          {name}
        </ThemeTypography>
      </Box>
    </ActivityContainer>
  );

  return (
    <Container>
      <ConditionalRender condition={showOwner}>
        {({ True, False }) => (
          <>
            <True>
              <PartnerContainer onClick={handlePartnerClick}>
                <PartnerImage
                  alt={`Logo of ${title}`}
                  src={ownedBy?.logo?.url || defaultPartnerImage}
                />
                <ThemeTypography variant="BODY_LARGE_BOLD">{ownedBy?.name}</ThemeTypography>
                <Box mr={2} />
                {activityItem}
              </PartnerContainer>
            </True>

            <False>{activityItem}</False>
          </>
        )}
      </ConditionalRender>
      <Box mt={2} />

      {!!warnings?.length && (
        <Box display="flex" flexDirection="column" mb={2} gap={1}>
          {warnings.map(({ errorMessage }, index) => (
            <WarningTextContainer key={`warning-${index}`}>
              <WarningTag>
                <ThemeTypography variant="BODY_SMALL_BOLD" color="GRAY_100">
                  Warning
                </ThemeTypography>
              </WarningTag>
              <ThemeTypography variant="BODY_SMALL" color="GRAY_100">
                {errorMessage}
              </ThemeTypography>
            </WarningTextContainer>
          ))}
        </Box>
      )}

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <ThemeTypography variant="BODY_LARGE_BOLD" color="GRAY_40" textTransform="uppercase">
            Area
          </ThemeTypography>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <ThemeTypography variant="BODY_SMALL">{round(areaSize, 3)} km²</ThemeTypography>
        </Grid>
      </Grid>

      <FlexBox mt={2} gap={1}>
        {showOwner && ownedBy?.id && (
          <ThemeButton
            color="BLUE_ICE"
            startIcon={<Icon name="link" />}
            onClick={handlePartnerClick}
          >
            Show partner
          </ThemeButton>
        )}
        {!!onEditClick && (
          <ThemeButton color="BLUE_ICE" startIcon={<Edit size={16} />} onClick={onEditClick}>
            Edit cultivation areas
          </ThemeButton>
        )}
      </FlexBox>
    </Container>
  );
};

export default CultivationFarmAreaInfoPopup;
