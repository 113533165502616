import { AccordionDetails, AccordionSummary, Box } from '@mui/material';
import ForestChangeLegendImage from 'assets/img/forest_change_legend.png';
import LandcoverLegendImage from 'assets/img/landcover_legend.png';
import { ThemeTypography } from 'designSystem';
import { Accordion } from 'designSystem/DataDisplay/Accordion/Accordion';
import RiskBadge from 'designSystem/DataDisplay/RiskBadge/RiskBadge';
import Icon from 'designSystem/Primitives/Icon/Icon';
import moment from 'moment';
import React, { FC } from 'react';
import { ChangeDetection, GeoCParcel, RiskLevel } from 'types/compliance.types';
import DeforestationImage from './DeforestationImage';

interface IRiskAccordionProps {
  parcel: GeoCParcel;
  hasBorder?: boolean;
}

const RiskAccordion: FC<IRiskAccordionProps> = ({ parcel, hasBorder }) => {
  return (
    <Accordion elevated slotProps={{ transition: { unmountOnExit: true, timeout: 600 } }}>
      <AccordionSummary>
        <Box display="flex" alignItems="center">
          <Box display="flex" mr={1}>
            <Icon name="location" size="x-large" color="blue-ice" />
          </Box>
          <Box>
            <Box display="flex" alignItems="center">
              {parcel.cultivatedAreas.farm.title && (
                <Box mr={0.75}>
                  <ThemeTypography variant="ITEM_TITLE">
                    {parcel.cultivatedAreas.farm.title}
                  </ThemeTypography>
                </Box>
              )}
              <RiskBadge riskLevel={RiskLevel[parcel.deforestationRisk]} />
            </Box>
            {parcel.id && (
              <ThemeTypography variant="BODY_LARGE" color="GRAY_60">
                ID: {parcel.id}
              </ThemeTypography>
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" gap={1} flexWrap="wrap">
          <DeforestationImage
            headline="Land cover"
            subHeadline={moment(parcel.baselineDate).format('YYYY-MM-DD')}
            imageSrc={parcel.landcoverPngBaselineDate}
            legendSrc={LandcoverLegendImage}
            openFullScreen
          />
          <DeforestationImage
            headline="Start date"
            subHeadline={moment(parcel.baselineDate).format('YYYY-MM-DD')}
            imageSrc={parcel.rgbPngBaselineDate}
            openFullScreen
          />
          <DeforestationImage
            headline="HarvestDate"
            subHeadline={moment(parcel.harvestDate).format('YYYY-MM-DD')}
            imageSrc={parcel.rgbPngProductionEnddate}
            openFullScreen
          />
          <DeforestationImage
            headline="Period change"
            subHeadline="Start - Harvest"
            imageSrc={parcel.forestChangePng}
            legendSrc={ForestChangeLegendImage}
            displayBorder
            openFullScreen
          />
          <Box flexDirection="column">
            <ThemeTypography variant="LABEL_INPUT">Change detection</ThemeTypography>
            <Box mb={0.5} />
            <RiskBadge
              riskLevel={RiskLevel[parcel.deforestationRisk]}
              text={ChangeDetection[parcel.deforestationRisk]}
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default RiskAccordion;
